import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Icons } from 'tc-minibox';
import { icons_config } from '../../../config/context.config';
import { dropdown_sx} from '../../../config/mui_style.config';

export interface ListElement {
    id: string | number;
    name: string | number;
    text: string;
}

export interface DropDownProps {
    reference: string;
    hover: any;
    setHover: React.Dispatch<React.SetStateAction<any>>;
    zIndex_list?: number;
    value: any;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    list: ListElement[];
    label?:string; 
}

export default function DropDown(props: DropDownProps) {
    /*
        PROPS :
            - reference
            - hover,
            - setHover,
            - value,
            - setValue,
            - list => (keys = ['id', 'name', 'text'])
    */

    return (
        <div
            className='dropdown'
            style={{ zIndex: props.zIndex_list ?? 1000 }}
            onMouseEnter={() => { props.setHover(props.reference); }}
            onMouseLeave={() => { props.setHover(null); }}
        >
        <FormControl fullWidth variant="outlined" sx={dropdown_sx}>
            {/* Définir l'étiquette avec l'option shrink true */}

            <Select
                labelId="select-label" // Associe ce Select à l'InputLabel via l'id
                sx={{ zIndex: 3500 }}
                label={props.label ?? "Choisissez une option"} // Le texte de l'étiquette à afficher dans le Select
                value={props.value}
                onChange={(event) => {
                    props.setValue(event.target.value);
                }}
                MenuProps={{
                    PaperProps: {
                        style: { zIndex: props.zIndex_list ?? 1300 },
                        className: 'custom-popover-class',
                    }
                }}
            >
                {props.list.map((el) => (
                    <MenuItem key={el.id} value={el.name}>
                        {el.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        </div>
    );
}



export interface ListElement {
    id : string |  number,
    name : string | number,
    text : string, 
}

export interface DropDownProps {
    reference : string,
    hover : any, 
    setHover : React.Dispatch<React.SetStateAction<any>>,
    zIndex_list? : number,
    value : any, 
    setValue : React.Dispatch<React.SetStateAction<any>>,
    list : ListElement[], 
}

export function DropDown2(props:DropDownProps) {
    /*
        PROPS : 
            - reference
            - hover, 
            - setHover, 
            - data, 
            - setData, 
            - list => (keys = ['id', 'name', 'text'])
    */
    return (
        <div 
            className='dropdown'
            style={{zIndex : props.zIndex_list?? 1000}}
            onMouseEnter={() => { props.setHover(props.reference)}}
            onMouseLeave={() => { props.setHover(null)}}
    >
        <p>
            {props.list.find((el) => el.name === props.value)?.text}
        </p>
        <div 
            style = {{transform : `rotate(${props.hover === props.reference ? "180deg" : "0"})`}}
            className='tc-icons'
        >
            <Icons name = "chevron-up" {...icons_config}/>
        </div>

        {
            props.hover === props.reference ? 
                <div 
                    //transition = {{duration : 0.1}}
                    className='dropdown--list'
                >   
                    {
                        props.list.filter(el => el.name !== props.value).map((el, position)=> (
                                <div 
                                    //whileHover = {{scale : 1.1}}
                                    //whileTap = {{scale : 0.90}}
                                    onClick = {() => {
                                        props.setHover(null)
                                        props.setValue(el)
                                    }}
                                    key = {el.id}
                                >
                                    {el.text}
                                </div>
  
                        ))
                    }
                </div>
            : 
                null
        }

    </div>
    )
}