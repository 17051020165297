import { CircularProgress, IconButton, InputAdornment, MenuItem, Paper, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { textField_sx } from "../../../config/mui_style.config";
import { AddRounded, SearchRounded } from "@mui/icons-material";
import { motion } from "framer-motion";
import { submenu_variants } from "./SearchSelect";
import { StringUtils } from "tc-minibox";
import { Box } from "@mui/system";
import { Axios } from "axios";

export interface SearchSelect2Props {
    value: ListItem,
    defaultList?: ListItem[],
    zIndex_list?: number,
    handleClick: (e: ListItem) => void;
    adding?: boolean,
    server: {
        route: string;
        axios: Axios;
        body?: { [key: string]: any };
    };
    no_data_text?: string,
    handleAdd?: (e: string) => void,
}

type ListItem = {
    text: string;
    id: string | number;
    [key: string]: any;
};

interface Search {
    open: boolean,
    status: "pending" | "resolve" | "reject",
    input: string,
    list: ListItem[]
}

export default function SearchSelect2(props: SearchSelect2Props) {

    //* STATES
    const [search, setSearch] = useState<Search>({
        open: false,
        status: "pending",
        input: props.value.text,
        list: props.defaultList || []
    });
    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);


    //* FUNCTIONS
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearch(state => ({
            ...state,
            input: newValue,
            status: "pending"
        }));
    }

    const handleFocus = () => {
        setIsFocused(true);
        if (props.defaultList) {
            setSearch(state => ({ ...state, open: true, status: "resolve" }));
        }
    }

    const handleBlur = () => {
        setIsFocused(false);
        if (search.input === '') {
            setSearch(state => ({ ...state, input: props.value.text }));
        }
    }

    const handleUnfocus = () => {
        setSearch(state => ({ ...state, open: false }));
    }

    const handleClick = (sl: ListItem) => {
        setSearch(state => ({ ...state, input: sl.text, open: false }));
        props.handleClick(sl);
    }

    const compareString = (a: string, b: string) => {
        return StringUtils.undress_string(a).includes(StringUtils.undress_string(b));
    }

    useEffect(() => {
        setSearch(state => ({ ...state, list: props.defaultList || [] }));
    }, [props.defaultList]);

    useEffect(() => {
        if (!compareString(props.value.text, search.input) && search.input !== '') {
            const timer = setTimeout(() => {
                setSearch(state => ({ ...state, open: true, status: "pending" }));
                props.server.axios
                    .post(props.server.route, { search_string: search.input, ...props.server.body })
                    .then((res: any) => {
                        setSearch(state => ({
                            ...state,
                            status: 'resolve',
                            list: res.data.data
                        }));
                    })
                    .catch(() => {
                        setSearch(state => ({
                            ...state,
                            status: 'resolve',
                            list: []
                        }));
                    });
            }, 250);

            return () => {
                clearTimeout(timer);
            };
        } else if (search.input === '') {
            setSearch(state => ({ ...state, list: props.defaultList || [], open: false, status: "resolve" }));
        }
    }, [search.input, props.server.axios, props.server.route, props.server.body, props.value.text, props.defaultList]);

    return (
        <div id="search-select2" style={{ position: 'relative' }}>
            <TextField
                ref={inputRef}
                variant="outlined"
                autoComplete="off"
                fullWidth
                value={search.input}
                placeholder="Tapez ici..."
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                sx={textField_sx}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <SearchRounded />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {search.open && <div className='list--background' onClick={handleUnfocus}></div>}
            {
                search.open &&
                <motion.div
                    {...submenu_variants}
                    style={{ position: 'absolute', zIndex: props.zIndex_list ?? 1300, width: '100%', top: '100%' }}
                >
                    <Paper elevation={3} style={{ width: '100%', maxHeight: '180px', overflowY: 'scroll' }}>
                        {props.adding && ![...search.list, props.value].find(ls => StringUtils.undress_string(ls.text) === StringUtils.undress_string(search.input)) && search.input.replaceAll(' ', '').length > 0 ?
                            <MenuItem
                                id="add-new-supplier"
                                onClick={() => {
                                    if (props.handleAdd) {
                                        props.handleAdd(search.input);
                                        setSearch(state => ({
                                            ...state,
                                            open: false
                                        }));
                                    }
                                }}
                            >
                                <AddRounded />
                                <p>{search.input}...</p>
                            </MenuItem>
                            : null}
                        {search.status === "resolve" ?
                            <React.Fragment>
                                {
                                    search.input.replaceAll(' ', '').length > 0 ?
                                        search.list.length > 0 ?
                                            search.list.map(sl => (
                                                <MenuItem onClick={() => handleClick(sl)} key={`SEARCH-SEL-LIST-ELMT${sl.id}`}>
                                                    {sl.text}
                                                </MenuItem>
                                            ))
                                            : <Box p={2} textAlign="center">{props.no_data_text}</Box>
                                        :
                                        null
                                }
                            </React.Fragment>
                            : <Box p={2} textAlign="center"><CircularProgress size={24} /></Box>}
                    </Paper>
                </motion.div>
            }
        </div>
    );
}
