import { useEffect, useRef, useState } from "react";
import { ServerProps } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";
import { useDispatch, useSelector } from "react-redux";
import { account_user } from "../../../store/account/selector.account";
import { FolderMongooseStructure, ListingElementStructure } from "../documents.utils";
import MainButton from "../../../tools/components/general/MainButton";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";



export interface NewFolderProps extends ServerProps {
    handleClose : () => void;
    current_folder : ListingElementStructure | null, 
    current_id_affair : string | null,
    setData : React.Dispatch<React.SetStateAction<any>>,
}



export default function NewFolder(props:NewFolderProps) {
    // * GLOBALE VARIABLES 
    const user_account = useSelector(account_user)
    const dispatch = useDispatch()

    //* REF
    const inputRef = useRef<HTMLInputElement>(null)
    
    // * STATES
    const [form, setForm] = useState<FolderMongooseStructure>({
        name : "",
        id_affair : props.current_id_affair,
        user : user_account.infos._id, 
        parent_id : props.current_folder === null ? null : props.current_folder._id,
        date_insert : new Date()
    })

    // * USE EFFECTS
    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    // * FUNCTIONS
    const handleSubmit = () => {
        if(form.name === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez renseigner le nom du dossier"
            }))
        }
        dispatch(setLoaderStatus({status : true}))
        props.server.post('/documents/new-folder', { new_folder : {...form, date_insert : new Date()}})
        .then((res) => {
            props.setData((state:any) => {
                return [
                    ...state, 
                    {
                        ...res.data.data, 
                        parent_id : props.current_folder === null ? null : props.current_folder,
                    }
                ]
            })
            props.handleClose()
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))

            dispatch(addRequestStatus({
                status : false, 
                message : "Erreur interne. Veuillez réessayer. "
            }))
        })
    }
    
    return (
        <BasePopup nameClass="documents--new-folder" handleClose = {props.handleClose}>
            <div id="name">
                <label htmlFor="name">Nom du dossier</label>
                <input 
                    ref = {inputRef}
                    type="text" 
                    name="name" 
                    id="name" 
                    value={form.name} 
                    onChange={(e) => setForm({...form, name : e.target.value})} 
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                />
                <MainButton handleClick={() => handleSubmit()}>
                    Enregistrer
                </MainButton>
            </div>
        </BasePopup>
    )
}