import { hostname, server_host } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";

export interface PreviewFileProps {
    url : string;
    handleClose : () => void;
}


export default function PreviewFile(props:PreviewFileProps) {
    return (
        <BasePopup nameClass="documents--preview-file" handleClose={props.handleClose}>
            <iframe src={`${server_host(props.url)}`} title="Preview file" frameBorder="0" width="100%" height="100%"></iframe>
        </BasePopup>
    )
}