import React, { useEffect, useState } from "react";
import { DatePicker, Icons } from "tc-minibox";
import {motion} from 'framer-motion'
import DropDown from "../../../tools/components/general/DropDown";
import { paymentStatuses } from "../payment.utils";
import SearchSelect from "../../../tools/components/general/SearchSelect";
import { ServerProps } from "../../../config/server.config";
import ContainerLoad from "../../../tools/components/general/ContainerLoad";
import SearchSelect2 from "../../../tools/components/general/SearchSelect2";


export interface FilterBoxProps extends ServerProps {
    manager : FilterManager,
    setManager : React.Dispatch<React.SetStateAction<FilterManager>>
}

type Events = {
    hover: string|null, 
    load : boolean
}


export type FilterManager = {
    analytic_code :  string, 
    payment_mode : "paid" | "unpaid" | "credit-note" |null,
    status : string, 
    supplier : string|null, 
    inv_date : Date|null, 
    receipt_date : Date|null, 
    due_date_start : Date|null, 
    due_date_end : Date | null
}


export const initial_state_manager:FilterManager = {
    analytic_code : "", 
    payment_mode : null, 
    status : "all", 
    supplier : null, 
    inv_date : null, 
    receipt_date : null, 
    due_date_start : null,
    due_date_end : null
}

export default function FilterBox(props:FilterBoxProps) {
    //* STATES
    const [events, setEvents] = useState<Events>({hover: null, load : false}) 

    const handleSelectPaymentMode = (mode:"paid"|"unpaid"|"credit-note"|null) => {
        if(props.manager.payment_mode === mode) {
            props.setManager(state => {return{...state, payment_mode : null}})
        } else {
            props.setManager((state:any) => {return{...state, payment_mode : mode}})
        }
    }

    useEffect(() => {
        if(events.load) {
            setTimeout(() => {
                setEvents(state => {return{...state, load : false}})
            }, 500)
        }
    },[events.load])

    return (
        <ContainerLoad load ={events.load}>
            <div className="filter-box">
                <div className="filter-box--title">Recherche avancée</div>
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="restart-button" 
                    onClick={() => {
                        setEvents(state => {return{...state, load : true}})
                        props.setManager(initial_state_manager)
                    }}
                >
                    <Icons name="arrows-repeat" color="#007aff"/>
                </motion.div>
                <div id="analytic-code">
                    <label htmlFor = "analytic-code">Code analytique</label>
                    <input 
                        type="text" 
                        id="analytic-code"
                        name = "analytic-code" 
                        value = {props.manager.analytic_code}
                        onChange = {(e) => {
                            const value = e.target.value;
                            if (value.match(/^[0-9]*$/)) {
                                props.setManager(state => {return{...state, analytic_code : value}})
                            }
                        }}
                        placeholder="Ex : 020"
                    />
                </div>
                <div id="date">
                    <label htmlFor = "date">Date de la facture</label>
                    <DatePicker
                        selected={props.manager.inv_date !== null ? new Date(props.manager.inv_date) : null } 
                        dateFormat="dd/MM/yyyy"
                        onChange = {(e:any) => props.setManager(state => {return{...state, inv_date : e}})}
                        locale="fr"       
                    />
                </div>
                <div id="date">
                    <label htmlFor = "date">Date de réception</label>
                    <DatePicker
                        selected={props.manager.receipt_date !== null ? new Date(props.manager.receipt_date) : null } 
                        dateFormat="dd/MM/yyyy"
                        onChange = {(e:any) => props.setManager(state => {return{...state, receipt_date : e}})}
                        locale="fr"       
                    />
                </div>
                <div id="date" className="due_date">
                    <label htmlFor = "date">Date de règlement</label>
                    <div className="due_date--container">
                    <DatePicker
                        selected={props.manager.due_date_start !== null ? new Date(props.manager.due_date_start) : null } 
                        dateFormat="dd/MM/yyyy"
                        onChange = {(e:any) => props.setManager(state => {return{...state, due_date_start : e}})}
                        locale="fr"       
                    />
                    <p>au</p>
                    <DatePicker
                        selected={props.manager.due_date_end !== null ? new Date(props.manager.due_date_end) : null } 
                        dateFormat="dd/MM/yyyy"
                        onChange = {(e:any) => props.setManager(state => {return{...state, due_date_end : e}})}
                        locale="fr"       
                    />
                    </div>

                </div>
                <div id="payment_mode">
                    <label htmlFor = "payment_mode">Statut de paiement</label>
                    <div id="payment_mode--select">
                        <motion.div 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleSelectPaymentMode("paid")}
                            id={props.manager.payment_mode === "paid" ? "paid-selected" : ""}
                        >
                            Payé
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleSelectPaymentMode("unpaid")}
                            id={props.manager.payment_mode === "unpaid" ? "unpaid-selected" : ""}
                        >
                            Non payé
                        </motion.div>
                        <motion.div
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => handleSelectPaymentMode("credit-note")}
                            id={props.manager.payment_mode === "credit-note" ? "credit-note-selected" : ""}
                        >
                            Avoir
                        </motion.div> 
                    </div>
                </div>

                <div id="status">
                    <label htmlFor = "status">Statut</label>
                    <DropDown
                        reference = {"STATUS-PAYMENTS"}
                        hover = {events.hover}
                        setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                        value = {props.manager.status}
                        setValue = {(e) => {
                            props.setManager(state => {return{...state, status : e}})
                        }}
                        list = {[
                            {
                                id : "all", 
                                name : "all" , 
                                text : "Tous les statuts", 
                            }, 
                            {
                                id : "no-status", 
                                name : "no-status" , 
                                text : "Aucun statut défini", 
                            }, 
                            ...paymentStatuses.map((ps:any) => {
                                return {
                                    id : ps.id, 
                                    name : ps.name, 
                                    text : ps.title
                                }
                            })
                        ]}
                    />
                </div>

                <div id="supplier">
                    <label htmlFor = "supplier">Fournisseur</label>
                    <SearchSelect
                        {...{
                            text_no_data : "Aucun fournisseur trouvé", 
                            handleClick : (data) => props.setManager(state => {return{...state, supplier : data.id as string}}),
                            handleDelete : () => props.setManager(state => {return{...state, supplier : null}}),
                            no_data_text : "Aucun fournisseur trouvé", 
                            server : {
                                axios : props.server,
                                route : "/invoicing/search-suppliers"
                            },
                            value : props.manager.supplier,
                        }}
                    />
                </div>




            </div>
        </ContainerLoad>
    )
}