/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react' ; 
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addRequestStatus, set_popup_warning, setLoaderStatus } from '../../../store/global/actions.global';
import { ServerProps } from '../../../config/server.config';
import { StringUtils, URLUtils } from 'tc-minibox';
import BaseApp from '../../../tools/components/general/BaseApp';
import BaseContainer from '../../../tools/components/general/BaseContainer';
import { DataType} from 'tc-minibox/dist/types/table/index.table';
import SupplierInvDeposit from './layouts/Deposit';
import ListingInv from './layouts/Listing';
import StepsText from '../../../tools/components/general/StepsText';
import MainButton from '../../../tools/components/general/MainButton';
import {ReactComponent as PrintingInvoice} from '../../../assets/svg/illustrations/printing_invoice.svg'

interface SuppliersInvProps extends ServerProps {

}

export interface SuppliersInvManager {
    initialize : boolean, 
    mode : "listing", 
    deposit : boolean, 
    selected_data : number | null
    load_data_grid : boolean
}

export default function SuppliersInv(props:SuppliersInvProps) {
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params = searchParams.get('id_affair');

    const [manager, setManager] = useState<SuppliersInvManager>({
        initialize : false, 
        mode : "listing", 
        deposit : false,
        selected_data : null, 
        load_data_grid : false
    })

    const [invoices, setInvoices] = useState<DataType>([])

    const handleChangeManager = (name:string, val:any) => setManager(state => {return{...state, [name] : val}})

    useEffect(() => {
        document.title = "Facturation fournisseurs - MyOGEC";
    }, []);

    useEffect(() => {
        if(!manager.initialize) {
            setInvoices([])
            //dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            setManager(state=> {return {...state, load_data_grid : true}})
            props.server.get('/invoicing/listing')
            .then(res => {
                setManager(state => { return {...state, initialize : true}})
                const data = res.data.data.map((dt:any) => {
                    return {
                        ...dt, 
                        dates : Object.fromEntries(Object.entries(dt.dates).map(([key, value]) => [key, new Date(value as string)]))
                    }
                })
                setInvoices(data)
                if(id_affair_params !== null) {
                    const parsedValue = parseInt(id_affair_params);
                    
                    // Check if parsedValue is not undefined
                    if(!StringUtils.isUndefinedValue(parsedValue)) {
                        setManager(state => { return {...state, selected_data: parsedValue} });
                
                        // Assuming removeURLParameter expects a string, don't pass null
                        URLUtils.removeURLParameter('id_affair');
                    }
                }
                setManager(state=> {return {...state, load_data_grid : false}})
                //dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                //handleChangeManager("initialization", "resolve")
                //dispatch(setLoaderStatus({status : false}))
                setManager(state=> {return {...state, load_data_grid : false}})
            })
        }
    }, [manager.initialize])

    return (
        <React.Fragment>
            {/* DEPOSIT */}
            {
                
                manager.deposit ? 
                    <SupplierInvDeposit
                        setManager={setManager}
                        handleClose = {(status:boolean) => {
                            if(status) {
                                return dispatch(set_popup_warning({
                                    status : true, 
                                    text : "Êtes-vous sûr de vouloir quitter cette page ? Vous perdrez toutes les données importées.", 
                                    handleYes : () => setManager(state => {return{...state, deposit : false}})
                                }))
                            }
                            return setManager(state => {return{...state, deposit : false}})
                            
                        }}
                        server = {props.server}
                        
                    />
                : 
                    null
            }

            <BaseApp>
                <BaseContainer nameClass="invoicing">
                    <StepsText
                        title = "Facturation fournisseurs"
                        position = {{
                            x : 40, 
                            y : 0
                        }}
                    />

                    <ListingInv 
                        server={props.server}
                        data = {invoices}
                        setData={setInvoices}
                        handleAdd = {() => handleChangeManager('deposit', true)}
                        manager={manager}
                        setManager={setManager}
                        load_data_grid = {manager.load_data_grid}
                        setLoadDataGrid={(val:boolean) => setManager(state => {return{...state, load_data_grid : val}})}
                    />




                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}