/* eslint-disable no-control-regex */
import { useRef, useState } from "react";
import { ServerProps } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";
import MainButton from "../../../tools/components/general/MainButton";
import { ListingElementStructure } from "../documents.utils";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";


export interface RenameElementProps extends ServerProps {
    handleClose: () => void;
    element : ListingElementStructure, 
    setContent : React.Dispatch<React.SetStateAction<ListingElementStructure[]>>,
}


export default function RenameElement(props:RenameElementProps) {
    const dispatch = useDispatch()
    var extName:string = props.element.name.split('.').pop() || ""
    //* REF
    const inputRef = useRef<HTMLInputElement>(null)

    const [name, setName] = useState<string>(props.element.name.replace(`.${extName}`, ""))

    const handleSubmit = () => {
        if(name === "") {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez renseigner le nom du dossier"
            }))
        }
        if(name === props.element.name) {
            return props.handleClose()
        }

        const formContainer = {
            id : props.element._id,
            name : props.element.type === "file" ? `${name}.${extName}` : name,
            type : props.element.type,
        }
        dispatch(setLoaderStatus({status : true}))
        props.server.post('/documents/rename-element', formContainer)
        .then(res => {
            props.setContent((state) => {
                return state.map((element) => {
                    if(element._id === props.element._id) {
                        return {
                            ...element,
                            name : formContainer.name
                        }
                    }
                    return element
                })
            })
            props.handleClose()
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    return (
        <BasePopup
            handleClose={props.handleClose}
            nameClass="documents--new-folder"
        >
            <div id = "name">
                <label htmlFor="name">{`Nouveau nom du ${props.element.type === "folder" ? "dossier" : "fichier"}`}</label>
                <input 
                    ref = {inputRef}
                    type="text" 
                    name="name" 
                    id="name" 
                    value={name} 
                    onChange={(e) => {
                        // Regex to match any character that is not a letter, digit, or whitespace
                        const regex = /[<>:"/\\|?*\u0000-\u001F.]/g;
                        
                        // Remove invalid characters
                        const filteredInput = e.target.value.replace(regex, '');
                        
                        setName(filteredInput);
                    }} 
                    onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
                />
                <MainButton handleClick={() => handleSubmit()}>
                    Modifier
                </MainButton>
            </div>
        </BasePopup>
    )
}