//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

// Définir la variable hostname
export const hostname = window.location.hostname === 'app.myogec.com' ? 'https://app.myogec.com' : 'http://localhost:4000';

// Configuration du socket
export const socket_server = io(`${hostname}`, { transports : ['polling'] });

// Fonction pour obtenir l'URL du serveur
export const server_host = (route = "") => { return `${hostname}/api/${route}` }

export const mui_license_key = "e275613fc0feac0464fc2412eff7b005Tz04OTY5MixFPTE3NDYzNjcwNTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
