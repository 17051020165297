import { ChildrenSignUpProps } from "../ForgotPwd" ; 
import MainButton from "../../../tools/components/general/MainButton";
import { Button, TextField } from "@mui/material";
import { button_sx, textField_sx } from "../../../config/mui_style.config";


export default function Form(props:ChildrenSignUpProps) {

    return (
        <div className="forgot-pwd--props.form">
            <h1>Mot de passe oublié</h1>
            <p>Si vous avez un compte, un code de vérification vous sera envoyé par e-mail.</p>
            <TextField
                id="outlined-email" 
                name = "mail"
                autoComplete='off'
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(e) => props.setForm(state => {return{...state, mail : e.target.value}})}
                value={props.form.mail}
                label="E-mail" 
                variant="outlined"
                sx={textField_sx}
                placeholder = "xxxxx@example.com"
                inputProps={{
                    
                }}
            />
            <Button variant="contained" sx = {button_sx} onClick={props.handleSubmit}>Suivant</Button>

        </div>
    )
}