import { useEffect, useRef, useState } from "react";
import { ServerProps } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";
import { useDispatch, useSelector } from "react-redux";
import { account_user } from "../../../store/account/selector.account";
import { FolderMongooseStructure, ListingElementStructure } from "../documents.utils";
import MainButton from "../../../tools/components/general/MainButton";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import ImportModule, { ImportProps } from "../../../tools/components/general/ImportModule";



export interface NewFileProps extends ServerProps {
    handleClose : () => void;
    current_folder : ListingElementStructure | null, 
    current_id_affair : string | null,
    setData : React.Dispatch<React.SetStateAction<any>>,
}



export default function NewFile(props:NewFileProps) {
    // * GLOBALE VARIABLES 
    const user_account = useSelector(account_user)
    const dispatch = useDispatch()

    //* REF
    const inputRef = useRef<HTMLInputElement>(null)
    
    // * USE EFFECTS
    useEffect(() => {
        inputRef.current?.focus()
    }, [])

    const import_config:ImportProps = {
        subtitle : "Importez votre fichier Excel",
        nameClass : 'new-affair--import', 
        multiple : true, 
        handleSubmit : (files:File[]) => {
            if(props.current_id_affair === null) {
                return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas importer de fichiers à ce niveau."}))
            }

            if(!props.current_folder?.can_delete && props.current_folder !== null) {
                return dispatch(addRequestStatus({status : false, message : "Ce dossier à lié à un module de l'application. Vous ne pouvez pas importer de fichiers ici."}))
            }


    
            const formContainer = new FormData()
            formContainer.append('id_affair', props.current_id_affair)
            formContainer.append('id_folder', props.current_folder !== null ? props.current_folder._id : "")
            files.forEach((file:any) => {
                formContainer.append('files', file)
            })
    
            dispatch(setLoaderStatus({status : true, message : "Téléchargement des fichiers..."}))
            props.server.post('/documents/import', formContainer, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(res => {
                props.setData((state:any) => {
                    return [...state, ...res.data.data]
                })
                props.handleClose()
                dispatch(addRequestStatus(res.data))
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
        }
    }
    
    return (
        <BasePopup nameClass="documents--new-file" handleClose = {props.handleClose}>
            <ImportModule {...import_config} />
        </BasePopup>
    )
}