import { useState } from "react"
import {AnimatePresence, motion} from 'framer-motion'; 
import DropDown from "../../../../tools/components/general/DropDown";
import { paymentDeadlines, type_payments } from "../../utils.suppliers";
import {ReactComponent as OnlineTransaction} from '../../../../assets/svg/illustrations/online_transaction.svg';


export interface PaymentChoiceProps {
    form : any, 
    setForm : React.Dispatch<React.SetStateAction<any>>, 
    handleChangeInput : (e:React.ChangeEvent<HTMLInputElement>, reg:any) => void
}

export default function PaymentChoice(props:PaymentChoiceProps) {
    const [events, setEvents] = useState({
        hover : null
    })

    
    return (
        <motion.div className='payment'>
            {/* PAYMENT DEADLINE */}
            <div className = "payment_deadline">
                <DropDown
                    reference = {"PAYMENT_DEADLINE"}
                    hover = {events.hover}
                    setHover = {(e:any) => setEvents(state => {return{...state, hover : e}})}
                    value = {props.form.payment_deadline}
                    setValue = {(e) => props.setForm((state:any) => {return{...state, payment_deadline : e}})}
                    list = {paymentDeadlines}
                    label = {"Délai de paiement"}
                />
            </div>
            <div className='payment_choice'>
                <DropDown
                    reference = {"TYPE_OF_PAYMENT_HOVER"}
                    hover = {events.hover}
                    setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                    value = {props.form.payment_choice}
                    setValue = {(e) => {
                        props.setForm((state:any) => {return{...state, payment_choice : e}})
                    }}
                    list = {type_payments}
                    label = {"Mode de paiement"}
                />
            </div>

            {
                props.form.payment_choice === "bank_transfer" ?
                    <div 
                        className='payment_info'
                    >
                        <div className='codes'>
                            <div className='account_number'>
                                <label htmlFor='account_number'>IBAN</label>
                                <input
                                    type = "text"
                                    name = "account_number"
                                    value = {props.form.payment_info.account_number}
                                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => props.setForm((state:any) => {return{...state, payment_info : {...state.payment_info, account_number : e.target.value}}} )}
                                />
                            </div>
                            <div className='bank_code'>
                                <label htmlFor='bank_code'>BIC</label>
                                <input
                                    type = "text"
                                    name = "bank_code"
                                    value = {props.form.payment_info.bank_code}
                                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => props.setForm((state:any) => {return{...state, payment_info : {...state.payment_info, bank_code : e.target.value}}} )}
                                />
                            </div>
                        </div>
                        <div className='account_holder'>
                            <label htmlFor='account_holder'>Nom du titulaire</label>
                            <input
                                type = "text"
                                name = "account_holder"
                                value = {props.form.payment_info.account_holder}
                                onChange = {(e:React.ChangeEvent<HTMLInputElement>) => props.setForm((state:any) => {return{...state, payment_info : {...state.payment_info, account_holder : e.target.value}}} )}
                            />
                        </div>
                    </div>
                    
                : 
                    <div 
                        className='nomodule'>
                        <OnlineTransaction />
                    </div>
            }

        </motion.div>
    )
}