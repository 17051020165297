import React, { useEffect, useState } from "react";
import { ServerProps } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import { Icons } from "tc-minibox";
import {ReactComponent as ArrowRight} from '../../../assets/svg/icons/arrow_right.svg'



export interface MoveElementProps extends ServerProps {
    handleClose : () => void
}

type TreeElement = {
    _id : string,
    name : string,
    length : number, 
    content : TreeElement[],
}

interface MoveElementManager {
    initialize : boolean,
    selected_affair : any | null,
    selected_folders : any[],
}

export default function MoveElement(props:MoveElementProps) {
    const dispatch = useDispatch()
    const [folders, setFolders] = useState<any[]>([])
    const [manager, setManager] = useState<MoveElementManager>({
        initialize : false,
        selected_affair : null, 
        selected_folders : [],
    })

    //console.log(affairs)
    useEffect(() => {
        if(!manager.initialize && manager.selected_affair === null) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            props.server.get('/documents/move-element/initialize')
            .then(res => {
                setManager(state => {return{...state, mode : "listing" }})
                setFolders(res.data.data)
                setManager(state => {return{...state, initialize : true }})

                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [manager.initialize])

    useEffect(() => {
        if(!manager.initialize && manager.selected_affair !== null) {
            props.server.post('/documents/move-element/folder-content', {id_affair : manager.selected_affair})
            .then(res => {
                setFolders(res.data.data)
                setManager(state => {return{...state, initialize : true}})
            })
            .catch(err => {
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [manager])


    return (
        <BasePopup
            nameClass = "documents__move-element"
            handleClose={props.handleClose}
        >
        ff
        </BasePopup>
    )
}

interface RenderTreeProps extends ServerProps {
    folders : TreeElement[]
}

function RenderTree(props:RenderTreeProps) {
    const [selectedFolder, setSelectedFolder] = useState<string | null>(null)
    //const [manager, setManager] = useState<{initialize : }
    /*
    useEffect(() => {
        if(selectedFolder !== null) {
            props.server.post('/documents/move-element/folder-content', {id_affair : manager.selected_affair})
            .then(res => {
                setAffairs(state => {
                    return state.map((affair:TreeElement) => {
                        if(affair._id === manager.selected_affair) {
                            return {
                                ...affair,
                                content : res.data.data
                            }
                        }
                        return affair
                    })
                })
                setManager(state => {return{...state, selected_affair : null}})
                console.log(res.data.data)
            })
            .catch(err => {
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [selectedFolder])*/
    return (
        <div className="documents--move-element__tree">
            {
                props.folders.map((folder:TreeElement) => (
                    <React.Fragment>
                        <div 
                            onClick = {() => setSelectedFolder(folder._id)}
                            key = {folder._id} 
                            className="move-element--link"
                        >
                            {
                                folder["length"] > 0 ? 
                                    <div id = "arrow">
                                        <ArrowRight />
                                    </div>
                                : 
                                    null
                            }
                            <div id = "bubble"></div>
                            <div id = "name">{folder.name}</div>
                        </div>
                        {
                            folder.content.length > 0 ? 
                                <RenderTree 
                                    server = {props.server}
                                    folders={folder.content}
                                />
                            : 
                                null
                        }
                    </React.Fragment>
                ))
            }
        </div>
    )
}