
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react' ; 
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment' ; 
import { account_user } from '../../../../store/account/selector.account';
import { ServerProps, server_host } from '../../../../config/server.config';
import { addRequestStatus, setLoaderStatus, set_popup_warning } from '../../../../store/global/actions.global';
import BasePopup from '../../../../tools/components/general/BasePopup';
import { DataType } from 'tc-minibox/dist/types/table/index.table';
import MainButton from '../../../../tools/components/general/MainButton';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import PopupUpdateInv from './update/PopupUpdateInv';
import Reassignment from './reassignment/Reassignment';
import { green_style_type_payments, type_payments } from '../../../suppliers/utils.suppliers';
import { credit_note_style } from '../suppliersinv.utils';
import MemoryAffairAid from './memory_aid/MemoryAffairAid';
import { DataGridPro, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { frFR } from '@mui/x-data-grid/locales';
import { button_sx, data_grid_x_sx } from '../../../../config/mui_style.config';
import { Button, IconButton, Menu, MenuItem, Tab, Tabs, Tooltip } from '@mui/material';
import { AddCircleRounded, CancelRounded, ChangeCircleRounded, DeleteRounded, EngineeringRounded, InfoRounded, IosShareRounded, SmartButton } from '@mui/icons-material';
import MuiSearchBox from '../../../../templates/MuiSearchBox';
import NoDataGrid from '../../../../templates/NoDataGrid';
import { SuppliersInvManager } from '../SuppliersInv';
import { Box } from '@mui/system';


export interface ListingProps extends ServerProps {
    data : any[],
    setData : React.SetStateAction<any>, 
    handleAdd : () => void, 
    manager : SuppliersInvManager,
    setManager : React.SetStateAction<any>, 
    load_data_grid : boolean, 
    setLoadDataGrid : (val:boolean) => void
}

export interface ListingInvManager {
    initialization : boolean, 
    preview : {
        status : boolean, 
        content : any
    }, 
    comment : {
        status : boolean, 
        mode : null | "refused" | "validated" | "sent" | "pending", 
        selected_data : string[]
    }, 
    refused : {
        status : boolean, 
        content : any
    }, 
    reassign : {
        status : boolean,
        selected_data : any
    }, 
    memory_aid : boolean, 
    search_string : string,
    status : string, 
    message : string | null, 
    filter : string, 
    selected_data : any, 
    business_manager_mode : boolean, 
    status_filter : string
}

export const validation_config = [
    {
        id : "VALID-CONFIG-NULL", 
        status : "pending", 
        text : "En attente", 
        style : {
            color : "#d28801", 
            backgroundColor : "#ffa50036"
        }
    }, 
    {
        id : "VALID-CONFIG-TRUE", 
        status : "validated", 
        text : "Validé", 
        color : "green", 
        style : {
            color : "green", 
            backgroundColor : "rgb(98 255 64 / 16%)"
        }
    }, 
    {
        id : "VALID-CONFIG-FALSE", 
        status : "refused", 
        text : "Refusé", 
        style : {
            color : "red", 
            backgroundColor : "#ff000014"
        }
    }, 

]

export default function ListingInv(props:ListingProps) {
    //* GLOBAL
    const dispatch = useDispatch()

    // * STATES
    const user_account = useSelector(account_user)
    const [actionsAnchor, setActionsAnchor] = useState<null | HTMLElement>(null)
    const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null)

    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [manager, setManager] = useState<ListingInvManager>({
        initialization : false, 
        comment : {
            status : false, 
            mode : null, 
            selected_data : []
        }, 
        preview : {
            status : false,
            content : null
        }, 
        refused : {
            status : false, 
            content : {}
        }, 
        reassign : {
            status : false,
            selected_data : []
        },
        memory_aid : false, 
        status : "pending", 
        message : null, 
        filter : "all", 
        selected_data : null, 
        business_manager_mode : false, 
        search_string : "", 
        status_filter : "all"
    })

    // * CONFIG VARIABLES
    // !!! PARAMETRAGE A FAIRE
    /*const filter_config = [
        {
            id : "FILTER-CONFIG-ALL",
            text : "Tous",
            name : "all",
            status : "all"
        }, 
        {
            id : "FILTER-CONFIG-TO-VALIDATE",
            text : "En attente",
            name : "to_validate", 
            status : "pending"
        },
        {
            id : "FILTER-CONFIG-VALID",
            text : "Validés",
            name : "valid", 
            status : "validated"
        },
        {
            id : "FILTER-CONFIG-REFUSED",
            text : "Refusés",
            name : "refused",
            status : "refused"
        },
        {
            id : "FILTER-CONFIG-SENT",
            text : "Envoyés",
            name : "sent",
            status : "sent"
        },
    ]*/

    const tbody:DataType = props.data.map((inv:any, index:number) => {
        const last_comment_log = inv.comments_log.length === 0 ? null : inv.comments_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]

        
        return {
            ...inv, 
            id : index, 
            index : index, 
            tc_affairs_numbers :  inv.id_affairs.map((aff:any) => aff.id.affair_number).join(inv.id_affairs.length === 1 ? '' : ' ; '), 
            tc_supplier : inv.id_supplier.name, 
            tc_due_date : inv.dates.due_date,
            tc_receipt_date : inv.dates.receipt_date,
            tc_inv_date : inv.dates.inv_date,
            tc_total_ht : `${(inv.total_ttc - inv.total_tva).toFixed(2)} €`,
            tc_total_tva : `${inv.total_tva.toFixed(2)} €`,
            tc_total_ttc : `${inv.total_ttc.toFixed(2)} €`,
            //${paymentStatuses.find((ps:any) => ps.name === last_comment_log.name)?.title}
            tc_payments_mode : last_comment_log === null ? `${type_payments.find((tp:any) => tp.name === inv.id_supplier.payment_choice)?.text} (Par défaut)` : `${last_comment_log.content.length > 0 ? last_comment_log.content : "Aucun commentaire"}`, 
            tc_designations : ""
        }
    })
    

    const filter_config = [
        {
            name : "all", 
            title : "Tous",
        }, 
        {
            name : "pending", 
            title : "En attente",
        }, 
        {
            name : "validated", 
            title : "Validés",
        }, 
        {
            name : "refused", 
            title : "Refusés",
        }, 
        {
            name : "sent", 
            title : "Envoyés",
        }, 
    ]

    const filter_custom = (rows:any[]) => {
        var cleaned_data:any = rows.map((row) => {
            return {
                ...row,
                search_string: `${row.tc_affairs_numbers} ${row.doc_number} ${row.inv_number} ${row.tc_supplier} ${row.tc_due_date} ${moment(row.tc_inv_date).format("DD/MM/YYYY")} ${row.tc_total_ht} ${row.tc_total_tva} ${row.tc_total_ttc} ${row.tc_payments_mode} ${row.tc_designations}`.replaceAll(" ", ""),
            };
        })

        const search_string = manager.search_string.toLowerCase().replaceAll(" ", "");  // Assumé depuis votre gestionnaire de recherche
        const matchData = cleaned_data.filter((da:any) => {
            const match = da.search_string.match(new RegExp(search_string, "i"));  // Utilise RegExp pour la correspondance insensible à la casse
            return match != null;
        }).sort((a:any, b:any) => {
            const matchA = a.search_string.match(new RegExp(search_string, "i"));
            const matchB = b.search_string.match(new RegExp(search_string, "i"));
    
            // Calculer le nombre de correspondances (bien que match retourne juste la première correspondance, donc ce sera toujours 1 ou 0 dans ce cas)
            const countA = matchA ? matchA.length : 0;
            const countB = matchB ? matchB.length : 0;
    
            // Trier en fonction du nombre de correspondances (du plus élevé au plus bas)
            if (countA > countB) {
                return -1; // a avant b
            } else if (countA < countB) {
                return 1; // b avant a
            } else {
                return 0; // aucun changement d'ordre
            }
        });
        
        var result = rows.filter((da:any) => matchData.some((md:any) => md._id === da._id))
        
        if(manager.business_manager_mode) {
            result = result.filter((dt:any) => dt.id_affairs.map((aff:any)=> aff.id.manager).includes(user_account.infos._id) || dt.id_affairs.map((aff:any)=> aff.id.analytic_code).includes("000"))
        }

        if(manager.status_filter === "all") {
            return result
        }

        if(manager.status_filter === "sent") {
            return result.filter((dt:any) => dt.status_log.find((st:any) => st.status === "sent"))
        } 

        return result.filter((dt:any) => dt.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0].status === manager.status_filter)
        //return result.filter((dt:any) => props.data[dt[0]].status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0].status === manager.status_filter)
        
    }
    
    // ? Selected rows by original data (props.data)
    var get_info_selected_data = props.data.filter((dt:any) => selectedRows.includes(dt._id))
    get_info_selected_data = get_info_selected_data.map((st:any) => {
        return {
            ...st, 
            last_status : st.status_log.sort((a:any, b:any) => b.date - a.date)[0]
        }
    })

    // * FUNCTIONS
    const filtering_data = () => {
        var filtered_data = props.data
        if(manager.business_manager_mode) {
            filtered_data = filtered_data.filter((dt:any) => dt.id_affairs.map((aff:any)=> aff.id.manager).includes(user_account.infos._id) || dt.id_affairs.map((aff:any)=> aff.id.analytic_code).includes("000"))
        }
        if(manager.filter === "all") {
            return filtered_data
        }

        if(manager.filter === "sent") {
            return filtered_data.filter((dt:any) => dt.status_log.find((st:any) => st.status === "sent"))
        } 
        
        return filtered_data.filter((dt:any) => dt.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0].status === manager.filter)
    }

    const handleChangeStatus = (message : string) => {
        const formContainer = {
            status : manager.comment.mode, 
            user : user_account.infos._id, 
            date : new Date(), 
            comment : message, 
            selected_data : manager.comment.selected_data
        }

        return props.server.post('/invoicing/manage-inv-status', formContainer)
        .then(res => {
            dispatch(addRequestStatus(res.data))

            props.setData((state:any) => {
                return state.map((inv:any) => {
                    if(manager.comment.selected_data.includes(inv._id)) {
                        return {
                            ...inv, 
                            status_log : [...inv.status_log, {
                                status : manager.comment.mode, 
                                comment : message, 
                                date : new Date(), 
                                user : user_account.infos
                            }]
                        }
                    }
                    return inv
                })
            })

            setManager(state => {
                return {
                ...state, 
                comment : {status : false, mode : null, selected_data : []}
            }})
            //props.setManager((state:any) => {return {...state, initialize : false}})
            
            
            
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            setManager((state:any) => {return {...state, load : false}})
        })
        /*
        
        
        
        
        const selected_inv = selected_data.map((sd:any) => props.data[sd])

        if(selected_inv.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Les factures sélectionnés ont déjà ce statut."}))
        }

        props.setData(props.data.map(inv => {
            if(selected_inv.map((s_inv:any) => s_inv.id_inv).includes(inv.id_inv)) {
                return {...inv, validation : {...inv.validation, promise : "pending"}}
            }
            return inv
        }))

        return props.server.post('/invoicing/manage-inv-status', {selected_data : selected_inv, new_status : new_status, date : current_date, id_user : user_account.infos.id_user, message : message})
        .then(res => {
            dispatch(addRequestStatus(res.data))
            props.setData(props.data.map(inv => {
                if(selected_inv.map((s_inv:any)=> s_inv.id_inv).includes(inv.id_inv)) {
                    return {...inv, validation : { id_user : user_account.infos.id_user ,date : current_date, status : new_status, promise : "resolve", message : message}}
                }
                return inv
            }))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })*/
    }

    const handleDelete = (selected_data:any) => {
        if(selected_data.length === 0) {
            return dispatch(addRequestStatus({status : false, message : "Aucune facture sélectionnée."}))
        }

        dispatch(setLoaderStatus({status : true}))
        return props.server.post('/invoicing/delete-inv', {id_inv : selected_data})
        .then(res => {
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false}))
            props.setData(props.data.filter((inv:any) => !selected_data.includes(inv._id)))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })

    }

    const handleExport = async (selected_data:any, mode:string, attachements:boolean) => {
        dispatch(setLoaderStatus({status : true}))
        return props.server.post('/payments/export', {
            selected_data : selected_data,
            mode : mode, 
            attachements : attachements
        })
        .then(res => {
            //dispatch(addRequestStatus(res.data))
            const link = document.createElement('a');
            link.href = server_host(`${res.data.data}?token=${user_account.infos.token_doc}`);
            link.download = attachements ? 'Suivi facturation.zip' : "Suivi facturation.xlsx"; // Nom du fichier à télécharger
            link.target = '_blank'; // O
              // Ajouter le lien au DOM et le déclencher
                document.body.appendChild(link);
                link.click();

                // Supprimer le lien du DOM une fois le téléchargement terminé
                document.body.removeChild(link);

            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false}))
            
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
        /*
        try {
            const res = await props.server.post('/invoicing/export', { selected_data: selected_inv, date: current_date, id_user: user_account.infos.id_user });
            dispatch(addRequestStatus(res.data));

            const link = document.createElement('a');
            link.href = server_host(res.data.data);
            link.download = 'Factures.zip'; // Nom du fichier à télécharger
            link.target = '_blank'; // O


            // Ajouter le lien au DOM et le déclencher
            document.body.appendChild(link);
            link.click();

            // Supprimer le lien du DOM une fois le téléchargement terminé
            document.body.removeChild(link);

            dispatch(setLoaderStatus({ status: false }));
        } catch (err:any) {
            dispatch(setLoaderStatus({ status: false }));
            dispatch(addRequestStatus(err.response.data));
        }*/
    }

    const handleReassign = (selected_data:any) => {
        const checking_multiple_affairs = selected_data.map((sd:any) => sd.id_affairs.length > 1).includes(true)
        if(checking_multiple_affairs) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Impossible de réaffecter une affaire à une facture qui en possède plusieurs. Veuillez modifier les affaires de ces factures avant de les réaffecter."}))
        }
        return setManager(state => {return {...state, reassign : {status : true, selected_data : selected_data}}})
    }   

    const handleDeleteStatus = (data:any, id_inv:string) => {
        const selected_status_log = props.data.find((inv:any) => inv._id === id_inv).status_log
        const new_status_log = selected_status_log.filter((st:any) => st._id !== data._id)

        dispatch(setLoaderStatus({status : true}))
        props.server.post('/invoicing/delete-status-log', {id_inv : id_inv, new_status_log : new_status_log})
        .then(res => {
            dispatch(addRequestStatus(res.data))
            props.setData((state:any) => {
                return state.map((inv:any) => {
                    if(inv._id === id_inv) {
                        return {...inv, status_log : new_status_log}
                    }
                    return inv
                })
            })
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })

        
    }

    const element_sizes = {
        status : 120, 
        doc_number : 110,
        supplier : 250, 
        inv_number : 150, 
        inv_date : 100,
        reception_date : 100,
        payment_date : 110,
        total_ht : 90,
        total_tva : 90,
        total_ttc : 90,
        payment_mode : 300,
        designation : 300,
        codes : 100
    }

    const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
        setSelectedRows(newSelection as any);
    };

    // * COMPONENTS CONFIG
    const data = {
        columns : [
            {
                field: "inv_status",
                headerName: "Statut",
                minWidth: element_sizes.status,
                renderCell: (params:any) => {
                    const tr = params.row;
                    const sorted_status_log = tr.status_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime());
                    const last_status = sorted_status_log.filter((st:any) => st.status !== "sent")[0];
                    const config_status = validation_config.find(vc => vc.status === last_status.status);
        
                    return (
                        <Tippy
                            content={(
                                <div className='status_validation--tippy' style={{ maxHeight: '110px', overflowY: 'auto' }}>
                                    {sorted_status_log.map((st:any, index:any) => {
                                        const st_status_config = validation_config.find(vc => vc.status === st.status);
                                        return (
                                            <div className={`${st.status === "sent" ? "status_validation--tippy--sent" : ""}`} key={`SORTED_LOG_${tr._id}_${index}`}>
                                                {
                                                    index !== sorted_status_log.length - 1 ? 
                                                        <button onClick={() => handleDeleteStatus(st, tr._id)} id = "trash"><DeleteRounded /> </button>
                                                    :   
                                                        null
                                                }
                                                <div style={st_status_config?.style ? st_status_config.style : {}}>
                                                    {st.status === "sent" ? "Envoyé" : st_status_config?.text}
                                                </div>
                                                <p>{`${st.comment.trim().length === 0 ? "Aucun commentaire" : st.comment}`}</p>
                                                <p>
                                                    <span>{`Posté le ${moment(new Date(st.date)).format('DD/MM/YYYY')} à ${moment(new Date(st.date)).format('HH:mm')}`}</span>
                                                    <span>{`Par ${st.user.firstname} ${st.user.lastname}`}</span>
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            interactive={true}
                            trigger="mouseenter focus"
                            placement="bottom"
                            popperOptions={{
                                modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                            fallbackPlacements: ['top', 'right', 'left'],
                                        },
                                    },
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 10],
                                        },
                                    },
                                ],
                            }}
                        >
                            <div className={`status_validation status_validation${last_status.status === "sent" ? "--sent" : ""}`} style={config_status?.style ? config_status.style : {}}>
                                {tr.status_log.find((st:any) => st.status === "sent") ? <div id='inv-status-sent'></div> : null}
                                {`${config_status?.text}`}
                            </div>
                        </Tippy>
                    );
                },
                align: "center",
            },
            {
                field: "doc_number",
                headerName: "N° pièce comptable",
                minWidth: element_sizes.doc_number,
                flex : 1,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        !green_style_type_payments.includes(tr.id_supplier.payment_choice) ? 
                            <p className={`doc_number`}>
                                {tr.doc_number}
                            </p>
                        :
                            <Tippy
                            content={(
                                <p className={`doc_number--note`}>
                                    Mode de paiement auto
                                </p>
                            )}
                            interactive={false}
                            trigger="mouseenter focus"
                            placement="bottom"
                            popperOptions={{
                                modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                            fallbackPlacements: ['top', 'right', 'left'],
                                        },
                                    },
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 10],
                                        },
                                    },
                                ],
                            }}
                            >
                                <p className={`doc_number ${green_style_type_payments.includes(tr.id_supplier.payment_choice) ? "doc_number--green" : ""}`} style={{ ...tr.type === "credit-note" ? credit_note_style : {} }}>
                                    {tr.doc_number}
                                </p>
                            </Tippy>
                    );
                },
                align: "center",
            },
            {
                field: "tc_affairs_numbers",
                headerName: "Codes",
                minWidth: element_sizes.codes,
                flex : 1,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.tc_affairs_numbers}
                        </p>
                    );
                },
                align: "center",
            },
            {
                field: "inv_number",
                headerName: "N° de facture",
                minWidth: element_sizes.inv_number,
                flex : 1,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.inv_number}
                        </p>
                    );
                },
                align: "left",
            },
            {
                field: "tc_supplier",
                headerName: "Fournisseur",
                minWidth: element_sizes.supplier,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.tc_supplier}
                        </p>
                    );
                },
                align: "left",
            },
            {
                field: "tc_inv_date",
                headerName: "Date de la facture",
                minWidth: element_sizes.inv_date,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {moment(tr.tc_inv_date).format('DD/MM/YYYY')}
                        </p>
                    );
                },
                align: "center",
            },
            {
                field: "tc_receipt_date",
                headerName: "Date de réception",
                minWidth: element_sizes.reception_date,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {moment(tr.tc_receipt_date).format('DD/MM/YYYY')}
                        </p>
                    );
                },
                align: "center",
            },
            {
                field: "tc_due_date",
                headerName: "Date de règlement",
                minWidth: element_sizes.payment_date,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.is_invoice_on_receipt ? "À réception de la facture" : moment(tr.tc_due_date).format('DD/MM/YYYY')}
                        </p>
                    );
                },
                align: "center",
            },
            {
                field: "tc_total_ht",
                headerName: "H.T",
                minWidth: element_sizes.total_ht,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.tc_total_ht}
                        </p>
                    );
                },
                align: "right",
            },
            {
                field: "tc_total_tva",
                headerName: "TVA",
                minWidth: element_sizes.total_tva,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.tc_total_tva}
                        </p>
                    );
                },
                align: "right",
            },
            {
                field: "tc_total_ttc",
                headerName: "TTC",
                minWidth: element_sizes.total_ttc,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                            {tr.tc_total_ttc}
                        </p>
                    );
                },
                align: "right",
            },
            {
                field: "comment",
                headerName: "Désignation",
                minWidth: element_sizes.designation,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <div className='tc_comment'>
                            <Tippy
                                content={<div>{tr.comment}</div>}
                                interactive={true}
                                trigger="click"
                                placement="bottom"
                                popperOptions={{
                                    modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                                fallbackPlacements: ['top', 'right', 'left'],
                                            },
                                        },
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, 10],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <p style={tr.type === "credit-note" ? credit_note_style : {}}>{tr.comment}</p>
                            </Tippy>
                        </div>
                    );
                },
                align: "left",
            },
            {
                field: "tc_payments_mode",
                headerName: "Mode de règlement",
                minWidth: element_sizes.payment_mode,
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        <div className='tc_payments_mode'>
                            <Tippy
                                content={<div>{tr.tc_payments_mode}</div>}
                                interactive={true}
                                trigger="click"
                                placement="bottom"
                                popperOptions={{
                                    modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                                fallbackPlacements: ['top', 'right', 'left'],
                                            },
                                        },
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, 10],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <p style={tr.type === "credit-note" ? credit_note_style : {}}>{tr.tc_payments_mode}</p>
                            </Tippy>
                        </div>
                    );
                },
                align: "left",
            },
        ], 
        rows : tbody
    }
    
    return (
        <React.Fragment>
            {
                manager.memory_aid ? 
                    <MemoryAffairAid 
                        server={props.server}
                        handleClose={() => setManager(state => {return {...state, memory_aid : false}})}
                    />
                : 
                    null
            }

            {
                manager.reassign.status ? 
                    <Reassignment 
                        handleClose = {() => {
                            setManager(state => {return {...state,  reassign : {status : false, selected_data : []}}})
                            props.setManager((state:any) => {return {...state, initialize : false}})
                        }}
                        selected_data = {manager.reassign.selected_data}
                        server={props.server}
                        setData = {props.setData}
                    />
                : 
                    null
            }
            {
                manager.comment.status ? 
                    <PopupStatusMessage
                        handleClose = {() => {
                            setManager(state => {return {...state, comment : {status : false, mode : null, selected_data : [], content : ""}}})
                        }}
                        handleSubmit = {(message:any) => handleChangeStatus(message)}
                    />
                : null
            }
            {
                manager.preview.status ?
                    <PopupInvoicePreview
                        handleClose = {() => setManager(state => {return {...state, preview : {status : false, content : null}}})}
                        url = {manager.preview.content}
                    />
                : 
                    null
            }
            {
                manager.selected_data !== null ? 
                    <PopupUpdateInv 
                        server = {props.server}
                        data = {props.data}
                        selected_data={manager.selected_data}
                        filtering_data={filtering_data().map((el:any) => el._id)}
                        setManager={setManager}
                        handleClose = {() => {
                            //props.setManager((state:any) => {return {...state, initialize : false}})
                            setManager(state => {return {...state, selected_data : null}})}
                        }
                        setData = {props.setData}
                    />
                :       
                    null
            }

            <div className = "invoice-detail">
                <div className='invoice-detail--header'>
                    <div className='buttons'>
                        <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.handleAdd()}
                                startIcon={<AddCircleRounded />}
                                sx={{
                                    ...button_sx, 
                                    backgroundColor: '#2196f3', // Couleur de fond normale
                                    '&:hover': {
                                      backgroundColor: '#0d47a1' // Couleur de fond au survol
                                    },
                                }}
                            >
                                Ajouter
                        </Button>
                        {
                            user_account.infos.user_group === "direction" && selectedRows.length > 0 ?             
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<DeleteRounded />}
                                        onClick={() => {
                                            if(get_info_selected_data.map((inv:any) => inv.last_status.status).includes("sent")) {
                                                return dispatch(addRequestStatus({status : false, message : "Impossible de supprimer une facture envoyée au comptable."}))
                                            }

                                            dispatch(set_popup_warning({
                                                status : true, 
                                                text : `Êtes-vous sûr de vouloir supprimer ce${selectedRows.length > 1 ? "s" : ""} facture${selectedRows.length > 1 ? "s" : ""} ?`, 
                                                handleYes : () => handleDelete(selectedRows) 
                                            }))
                                            
                                        }}
                                        sx={{
                                            ...button_sx, 
                                            marginLeft : "5px", 
                                            backgroundColor: '#f54242', // Couleur de fond normale
                                            '&:hover': {
                                                backgroundColor: '#B71C1C' // Couleur de fond au survol
                                            },
                                        }}
                                    >
                                        Supprimer
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={(event) => setActionsAnchor(event.currentTarget)}
                                        startIcon={<SmartButton />}
                                        sx = {button_sx}
                                        className='buttons__actions'
                                    >
                                        Actions
                                    </Button>                
                                    <Menu
                                        anchorEl={actionsAnchor}
                                        open={Boolean(actionsAnchor)}
                                        onClose={() => setActionsAnchor(null)}
                                        className='buttons__actions--list' 
                                    >
                                        <MenuItem 
                                            className = "pending" 
                                            onClick={() => {
                                                setManager(state => {return {...state, comment : {status : true, mode : "pending", selected_data : selectedRows}}})
                                                setActionsAnchor(null)
                                            }}

                                        >En attente</MenuItem>
                                        <MenuItem 
                                            className = "valid" 
                                            onClick={() => {
                                                setManager(state => {return {...state, comment : {status : true, mode : "validated", selected_data : selectedRows}}})
                                                setActionsAnchor(null)
                                            }}
                                        
                                        >Valider</MenuItem>
                                        <MenuItem 
                                            className = "not-valid" 
                                            onClick={() => {
                                                setManager(state => {return {...state, comment : {status : true, mode : "refused", selected_data : selectedRows}}})
                                                setActionsAnchor(null)
                                            }}
                                        >Refuser</MenuItem> 
                                        {
                                            !get_info_selected_data.map((inv:any) => inv.last_status.status).includes("sent") ? 
                                                <MenuItem 
                                                    className = "to_send" 
                                                    onClick={() => {
                                                        setActionsAnchor(null)
                                                        dispatch(set_popup_warning({
                                                            status : true, 
                                                            text : `Confirmez-vous l'envoi de ${selectedRows.length > 1 ? "ces factures" : "cette facture"} au comptable ? Une fois envoyée, elle ne pourra ni être modifiée ni supprimée.`, 
                                                            handleYes : () => setManager(state => {return {...state, comment : {status : true, mode : "sent", selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})
                                                        }))
                                                    }}>
                                                        Envoyer
                                                </MenuItem>
                                            : 
                                                null

                                        }

                                    </Menu>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<ChangeCircleRounded />}
                                        onClick={() => handleReassign(get_info_selected_data)}
                                        sx={{
                                            ...button_sx, 
                                            backgroundColor: '#2196f3', // Couleur de fond normale
                                            '&:hover': {
                                              backgroundColor: '#0d47a1' // Couleur de fond au survol
                                            },
                                        }}
                                    >
                                        Réaffectation
                                    </Button>
                                </React.Fragment>
                            : 
                                null
                                                    
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => setExportAnchor(event.currentTarget)}
                            startIcon={<IosShareRounded />}
                            sx = {{
                                ...button_sx, 
                                marginLeft : "5px",
                                backgroundColor : "rgb(126, 125, 125)", 
                                "&:hover" : {
                                    backgroundColor : "rgb(91 90 90)", 
                                }
                            }}
                            className='buttons__export'
                        >
                            Exporter
                        </Button>                
                        <Menu
                            anchorEl={exportAnchor}
                            open={Boolean(exportAnchor)}
                            onClose={() => setExportAnchor(null)}
                            className='buttons__export--list' 
                        >
                            {
                                selectedRows.length > 0 ?
                                    <React.Fragment>
                                        <MenuItem
                                            onClick = {() => {
                                                handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", false)
                                                setExportAnchor(null)
                                            }}
                                        >
                                            Exporter la sélection
                                        </MenuItem>
                                        <MenuItem
                                            onClick = {() => {
                                                handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", true)
                                                setExportAnchor(null)
                                            }}
                                        >
                                            Exporter la sélection + pièces
                                        </MenuItem>
                                    </React.Fragment>
                                : 
                                    null

                            }
                            <MenuItem 
                                onClick={() => {
                                    handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", false)
                                    setExportAnchor(null)
                                }}
                            >Exporter tout</MenuItem>
                                <MenuItem 
                                onClick={() => {
                                    handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", true)
                                    setExportAnchor(null)
                                }}
                            >Exporter tout + pièces</MenuItem>
                        </Menu>






                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setManager(state => {return {...state, business_manager_mode : !state.business_manager_mode}})
                        }}
                        startIcon={manager.business_manager_mode ? <CancelRounded /> : <EngineeringRounded />}
                        sx={{
                            ...button_sx, 
                            marginRight : "5px", 
                            backgroundColor: manager.business_manager_mode ? "#724CA3" : '#9882a6', // Couleur de fond normale
                            '&:hover': {
                                backgroundColor: !manager.business_manager_mode ? "#724CA3" : '#9882a6' // Couleur de fond au survol
                            },
                        }}
                    >
                        {
                            manager.business_manager_mode ? 
                                "Désactiver mode resp. dossier"

                            : 
                                "Mode resp. dossier"
                        }

                    </Button>

                    <Tooltip 
                        title="Aide mémoire des affaires"
                        sx = {{marginRight : "5px"}}
                        onClick={() => setManager(state => {return {...state, memory_aid : true}})}
                    >
                        <IconButton>
                            <InfoRounded sx = {{color : "grey"}}/>
                        </IconButton>
                    </Tooltip>
                    <div className={`filters filters--${manager.status_filter}`}>
                    <Box sx={{ }}>
                        <Tabs
                            value={manager.status_filter}
                            onChange={(e, newValue) => setManager(state => {return{...state, status_filter : newValue}})}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                            variant="scrollable"
                            sx = {{width : "350px"}}
                            scrollButtons="auto"
                        >
                            {
                                filter_config.map((filter, index:number) => (
                                    <Tab
                                        key = {`FILTER-KEY-00${index}`} 
                                        value={filter.name} 
                                        label={`${filter.title} (${
                                            filter.name === "all" ? 
                                                props.data.length 
                                            : filter.name === "sent" ? 
                                                props.data.filter((dt:any) => dt.status_log.find((st:any) => st.status === "sent")).length
                                            : 
                                                props.data.map((dt:any) => dt.status_log.filter((st:any) => st.status !== "sent").sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]).filter((st:any) => st.status === filter.name).length
                                        })`}
                                    />
                                ))
                            }
                        </Tabs>
                    </Box>
                    </div>
                    <MuiSearchBox
                        search_string={manager.search_string}
                        setSearchString={(search_string) => setManager(state => {return {...state, search_string}})}
                    />
                </div>
                <div className='invoice-detail--content'>
                    <DataGridPro
                        loading={props.load_data_grid}
                        rows={filter_custom(data.rows)}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        rowHeight={40}
                        checkboxSelection
                        //disableColumnMenu={true} 
                        //disableColumnResize={true}
                        hideFooter={true}
                        editMode="row"
                        onRowSelectionModelChange={handleSelectionChange}
                        disableColumnReorder={false}
                        getRowId={(row:any) => row._id}
                        getRowClassName={(params) => {
                            // Exemple de logique pour ajouter une classe basée sur une condition
                            return params.row.type === "credit-note" ? 'MuiDataGrid-row--credit-note' : '';
                        }} 
                        onRowDoubleClick={(row) => {
                                dispatch(setLoaderStatus({status : true}))
                                props.server.post('/invoicing/select-inv', {id_inv : row.row._id})
                                .then(res => {
                                    const data = {
                                        ...res.data.data,
                                        url : server_host(res.data.data.url)
                                    }
                                    dispatch(setLoaderStatus({status : false}))
                                    setManager(state => {return{...state, mode : "detail", selected_data : data}})
                                })
                                .catch(err => {
                                    dispatch(setLoaderStatus({status : false}))
                                    dispatch(addRequestStatus(err.response.data))
                                })
                        }}
                        columns={data.columns.map(column => {
                            return {
                                ...column,
                                editable: false,
                                flex: 1, // Donne une flexibilité égale à chaque colonne
                            }
                        }) as any}
                        sx={{
                            ...data_grid_x_sx, 
                            border : "none!important",
                            "& .MuiFormControl-root, .MuiInputBase-root, .MuiInputBase-root input" : {
                                height : '100%',
                                justifyContent : 'center'
                            }, 
                            "& .MuiInputBase-input, .MuiFormControl-root" : {
                                padding : 0, 
                            },
                            "& .MuiInputBase-input" : {
                                paddingLeft : "10px", 
                                width : "calc(100% - 10px)", 
                            },
                            "& .MuiDataGrid-cell" : {
                                backgroundColor : "transparent!important", 
                            }, 
                            "& .MuiDataGrid-cell--textLeft *" : {
                                fontSize : "0.875rem"
                            }, 
                            ".MuiDataGrid-cell:focus" : {
                                outline : "none!important"
                            }
                        }} 
                    />
                    {
                        filter_custom(data.rows).length === 0 && props.manager.initialize ? 
                            <NoDataGrid />
                        : 
                            null
                    }
                </div>


            </div>

        </React.Fragment>
    )
}


function PopupStatusMessage(props:any) {
    const [message, setMessage] = useState("")

    return (
        <BasePopup
            nameClass = "popup-message-refused"
            handleClose = {props.handleClose}
        >
            <p>Souhaitez-vous ajouter un commentaire ?</p>
            <textarea
                value = {message}
                onChange = {(e) => setMessage(e.target.value)}
                placeholder='Tapez ici...'
            />
            <MainButton
                handleClick = {() => props.handleSubmit(message)}
            >
                Valider
            </MainButton>
        
        </BasePopup>
    )
}

function PopupInvoicePreview(props:any) {
    return (
        <BasePopup
            nameClass = "popup-invoice-preview"
            handleClose = {props.handleClose}
        >
            <iframe src={`${props.url}`} title="Web Page Viewer" className="iframe-container"/>
        </BasePopup>
    )
}