import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Icons, StringUtils } from 'tc-minibox'
import { addRequestStatus } from '../../../../../../store/global/actions.global'

export interface UpdatingInvTotalsProps {
    selected_data: any,
    selected_data_toMap: any,
    handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>, reg:RegExp|string) => void, 
    setData : (name:string, value:any) => void,
}

interface FormTotals {
    total_ht: string,
    total_tva: string,
}

export default function Totals(props:UpdatingInvTotalsProps) {
    const [form, setForm] = useState<FormTotals>({
        total_ht: `${(props.selected_data.total_ttc - props.selected_data.total_tva).toFixed(2)}`,
        total_tva: `${props.selected_data.total_tva.toFixed(2)}`
    })
    const [ht, setHt] = useState<string>(`${(props.selected_data_toMap.total_ttc - props.selected_data_toMap.total_tva).toFixed(2)}`)
    const dispatch = useDispatch()

    return (
        <React.Fragment>
            {/*TOTAL_HT */}
            <div className='total_ht'>
                <label htmlFor='total_ht'>Total HT</label>
                <div>
                    <input 
                        type = "text"
                        name = "total_ht"
                        value = {`${form.total_ht}`}
                        onBlur={(e:React.ChangeEvent<HTMLInputElement>) => {
                            const total_ht = parseFloat(form.total_ht)
                            if(isNaN(total_ht) ) {
                                props.setData('total_ht', 0)
                                return setForm({...form, total_ht: ""})
                            }
                            return props.setData('total_ht', form.total_ht)
                        }}
                        onFocus={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(props.selected_data.total_ht === "") {
                                setHt("-")

                                return setTimeout(() => {
                                    if (e.target) {
                                      e.target.setSelectionRange(ht.length, ht.length);
                                    }
                                  }, 0);
                            }

                            return setTimeout(() => {
                                if (e.target) {
                                  e.target.setSelectionRange("-".length, "-".length);
                                }
                            }, 0);

                        }}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(e.target.value.includes('-') && props.selected_data.type !== "credit-note") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'Pour que ce soit négatif, veuillez utiliser modifier le type de facture.'
                                }))
                            }

                            if(!/^-/.test(e.target.value) && props.selected_data.type === "credit-note" && e.target.value !== "") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'La valeur ne peut être positive pour un avoir. Veuillez modifier le type de facture.'
                                }))
                            }

                            const reg = props.selected_data.type === "credit-note" ? /^(\s*|-?\d*[.,]?\d*)$/gm : /^(?:\s*|\d+[.,]?\d*)$/gm
                            // /^(?:\s*|\d+[.,]?\d*)$/gm
                            var value = e.target.value
        
                            if (reg instanceof RegExp && !reg.test(value)) {
                                // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
                                // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
                                return;
                            }
                    
                            value = value.replace(',', '.')
                            setForm({...form, total_ht: value})

                            //props.setData('total_ht', value)
                            /*props.setData((state) => {
                                return state.map(st => {
                                    if(st.url === props.selected_data.url) {
                                        if(st.id_affairs.length > 1) {
                                            dispatch(addRequestStatus({
                                                status : true, 
                                                message : "Suite à cette modification, l'édition des affaires a été réinitialisée.", 
                                            }))
                                        }
                                        return {
                                            ...st, 
                                            id_affairs : [
                                                {
                                                    ...st.id_affairs[0], 
                                                    ht : value.trim().length > 0 ? parseFloat(value) : 0, 
                                                    ttc : (value.replace('-', "").trim().length > 0 ? parseFloat(value) : 0) + parseFloat(st.id_affairs[0].tva)
                                                }
                                            ], 
                                            total_ttc : (value.replace('-', "").trim().length > 0 ? parseFloat(value) : 0) + parseFloat(st.id_affairs[0].tva)
                                        }
                                    }
                                    return st
                                })
                            })*/
                        }}
                    />
                    <p>€</p>
                </div>


            </div>

            {/*TOTAL_TVA */}
            <div className='total_tva'>
                <label htmlFor='total_tva'>Total TVA</label>
                <div>
                    <input 
                        type = "text"
                        name = "total_tva"
                        value = {`${form.total_tva}`}
                        onBlur={(e:React.ChangeEvent<HTMLInputElement>) => {
                            const total_tva = parseFloat(form.total_tva)
                            if(isNaN(total_tva)) {
                                setForm({...form, total_tva: ""})
                                return props.setData('total_tva', 0)
                            } 
                            return props.setData('total_tva', form.total_tva)
                        }}
                        onFocus={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(props.selected_data.total_tva === "") {
                                props.handleChangeInput({
                                    target : {
                                        name: 'total_tva',
                                        value: "-"
                                    }
                                } as React.ChangeEvent<HTMLInputElement>, "")
                            }
                            setTimeout(() => {
                                if (e.target) {
                                  e.target.setSelectionRange("-".length, "-".length);
                                }
                              }, 0);
                        }}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                            if(e.target.value.includes('-') && props.selected_data.type !== "credit-note" && e.target.value !== "") {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'La valeur ne peut être négative pour une facture. Veuillez modifier le type de facture.'
                                }))
                            }

                            if(!/^-/.test(e.target.value) && props.selected_data.type === "credit-note" && (!["", "0"].includes(e.target.value))) {
                                return dispatch(addRequestStatus({
                                    status: false,
                                    message: 'La valeur ne peut être positive pour un avoir. Veuillez modifier le type de facture.'
                                }))
                            }

                            //const reg = props.selected_data.type === "credit-note" ? /^(\s*|-?\d*[.,]?\d*)$/gm : /^(?:\s*|\d+[.,]?\d*)$/gm
                            // /^(?:\s*|\d+[.,]?\d*)$/gm
                            const value = e.target.value
                            setForm({...form, total_tva: value})
                            //props.setData('total_tva', value)
                            /*props.setData((state) => {
                                return state.map(st => { 
                                    if(st.url === props.selected_data.url) {
                                        if(st.id_affairs.length > 1) {
                                            dispatch(addRequestStatus({
                                                status : true, 
                                                message : "Suite à cette modification, l'édition des affaires a été réinitialisée.", 
                                            }))
                                        }
                                        return {
                                            ...st, 
                                            id_affairs : [
                                                {
                                                    ...st.id_affairs[0], 
                                                    tva : value, 
                                                    ttc : (value.trim().length > 0 ? parseFloat(value) : 0) + parseFloat(st.id_affairs[0].ht)
                                                }
                                            ], 
                                            total_tva : value,
                                            total_ttc : (value.trim().length > 0 ? parseFloat(value) : 0) + parseFloat(st.id_affairs[0].ht)
                                        }
                                    }
                                    return st
                                })
                            })*/
                        }}
                    />
                    <p>€</p>
                </div>
                {StringUtils.isUndefinedValue(props.selected_data.total_tva) || props.selected_data.total_tva === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
                }
            </div>

            {/*TOTAL_TTC*/}
            <div className='total_ttc'>
                <label htmlFor='total_ttc'>Total TTC</label>
                <div>{`${((ht.trim().length > 0 ? parseFloat(ht) : 0) + (!isNaN(parseFloat(props.selected_data.total_tva)) ? parseFloat(props.selected_data.total_tva) : 0)).toFixed(2).replace('.', ',')} €`}</div>
                {/*
                
                
                StringUtils.isUndefinedValue(props.selected_data.total_ttc) || props.selected_data.total_ttc === "" ? 
                    <div className='status_input status_input--xmark'><Icons name = "xmark" color = "red"/></div> 
                : 
                    null
            */}
            </div>
        </React.Fragment>
    )
}