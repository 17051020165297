/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import { useDispatch } from 'react-redux';
import { ServerProps } from '../../config/server.config';
import { setLoaderStatus, addRequestStatus, set_popup_warning } from '../../store/global/actions.global';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { DataType} from 'tc-minibox/dist/types/table/index.table';
import { paymentDeadlines, type_payments } from './utils.suppliers';
import SupplierManager from './manager/Manager';
import { frFR } from '@mui/x-data-grid/locales';
import { DataGridPro, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import {button_sx, data_grid_x_sx} from '../../config/mui_style.config'
import { Button } from '@mui/material';
import { AddCircleRounded, DeleteRounded } from '@mui/icons-material';
import MuiSearchBox from '../../templates/MuiSearchBox';
import StepsText from '../../tools/components/general/StepsText';
import NoDataGrid from '../../templates/NoDataGrid';
import { is } from 'date-fns/locale';


export type SuppliersManager = {
    add : boolean
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : number | null,
    load : boolean, 
    search_string: string;
    update : {
        status : boolean, 
        content : any
    } 
}

const initialize_update = {
    status : false, 
    content : {}
}

export default function Suppliers(props:ServerProps) {
    // * VARIABLES
    const dispatch = useDispatch()
    
    

    //TO MANAGE COMPONENT

    const [manager, setManager] = useState<SuppliersManager>({
        add : false, 
        initialize : false,
        load : false, 
        mode : "listing",
        selected_data : null,
        search_string: "", 
        update : initialize_update
    })
    const [suppliers, setSuppliers] = useState<DataType>([])
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    //DATA 
    const tbody = suppliers.map((supp:any, index:number) => {
            return {
                ...supp, 
                id : index, 
                index : index, 
                payment_choice : type_payments.find(tp => tp.name === supp.payment_choice)?.text, 
                payment_deadline : paymentDeadlines.find(pd => pd.name === supp.payment_deadline)?.text, 
                is_invoice_on_receipt : supp.is_invoice_on_receipt ? "Oui" : "Non"
            }
        })


    // * USE EFFECTS

    useEffect(() => {
        document.title = "Annuaire fournisseurs - MyOGEC";
    }, []);

    //INITIALIZE
    useEffect(() => {
        if(!manager.initialize) {
            setManager(state => {return {...state, load : true}})
            //dispatch(setLoaderStatus({status : true, message : "Chargement des fournisseurs en cours..."}))
            props.server.get('/suppliers/initialize')
            .then(res => {
                setManager(state => { return {...state, initialization : "resolve"}})
                
                setSuppliers(res.data.data)
                //dispatch(setLoaderStatus({status : false, message : ""}))
                setManager(state => {return {...state, load : false, initialize : true}})
            })
            .catch(err => {
                //dispatch(addRequestStatus(err.response.data))
                setManager(state => {return {...state, load : false}})
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])


    // * FUNCTIONS 
    const handleDelete = () => {
        if(selectedRows.length > 0) {
            setManager(state => {return {...state, load : true}})
            const selected_data = suppliers.filter((supp:any) => selectedRows.includes(supp._id))
            props.server.post('suppliers/delete', {ids_supplier : selected_data } )
            .then(res => {
                setSuppliers(state => state.filter((supp:any) => !selectedRows.includes(supp._id)))
                dispatch(addRequestStatus(res.data))
                setManager(state => {return {...state, load : false}})
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                setManager(state => {return {...state, load : false}})
            })
        }
    }
    const handleDoubleClick = (selected_data:any) => {
        const sd = suppliers.find(supp => supp._id === selected_data._id)


        setManager(state => {
            return{
                ...state, 
                add : true, 
                update : {
                    status : true, 
                    content : sd
                }
        }})
    }

    const data = {
        columns : [
            { field: "name", headerName: "Nom", minWidth: "calc(100% - 400px)" },
            { field: "payment_choice", headerName: "Type de paiement", minWidth: "200px", textAlign: "center" },
            { field: "payment_deadline", headerName: "Échéances paiement", minWidth: "200px", textAlign: "center" }, 
            //{ field: "is_invoice_on_receipt", headerName: "À réception de la facture", minWidth: "200px", textAlign: "center" }
        ], 
        rows : tbody
    }

    // * COMPONENTS CONFIG

    const filter_custom = (rows:any[]) => {
        var cleaned_data:any = rows.map((row) => {
            return {
                ...row,
                search_string: `${row.name} ${row.payment_choice} ${row.payment_deadline}`.toLowerCase()
            };
        })

        const search_string = manager.search_string.toLowerCase();  // Assumé depuis votre gestionnaire de recherche
        const matchData = cleaned_data.filter((da:any) => {
            const match = da.search_string.match(new RegExp(search_string, "i"));  // Utilise RegExp pour la correspondance insensible à la casse
            return match != null;
        }).sort((a:any, b:any) => {
            const matchA = a.search_string.match(new RegExp(search_string, "i"));
            const matchB = b.search_string.match(new RegExp(search_string, "i"));
    
            // Calculer le nombre de correspondances (bien que match retourne juste la première correspondance, donc ce sera toujours 1 ou 0 dans ce cas)
            const countA = matchA ? matchA.length : 0;
            const countB = matchB ? matchB.length : 0;
    
            // Trier en fonction du nombre de correspondances (du plus élevé au plus bas)
            if (countA > countB) {
                return -1; // a avant b
            } else if (countA < countB) {
                return 1; // b avant a
            } else {
                return 0; // aucun changement d'ordre
            }
        });
        
        return rows.filter((da:any) => matchData.some((md:any) => md._id === da._id))
    }

    const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
        setSelectedRows(newSelection as any);
    };

    return (
        <React.Fragment>
            {

                manager.add ? 
                    <SupplierManager
                        update = {manager.update}
                        data = {suppliers}
                        setData = {setSuppliers}
                        server = {props.server}
                        handleAdd = {(val) => {
                            setSuppliers(state => {return[val, ...state]})
                        }}
                        handleClose = {() => setManager(state => {return{...state, add : false, update : initialize_update}})}
                    />
                : 
                    null
            }

            <BaseApp>

                <BaseContainer nameClass = "suppliers">
                    <StepsText
                        title = "Annuaire - Fournisseur"
                        position = {{
                            x : 40, 
                            y : 0
                        }}
                    />
                    <div className = "suppliers__header">
                        <div id = "buttons">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setManager(state => {return {...state, add : true}})}
                                startIcon={<AddCircleRounded />}
                                sx={{
                                    ...button_sx, 
                                    backgroundColor: '#2196f3', // Couleur de fond normale
                                    '&:hover': {
                                      backgroundColor: '#0d47a1' // Couleur de fond au survol
                                    },
                                }}
                            >
                                Ajouter
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<DeleteRounded />}
                                onClick={() => dispatch(set_popup_warning({
                                        status : true, 
                                        text : `Êtes-vous sûr de vouloir supprimer ce${selectedRows.length > 1 ? "s" : ""} fournisseur${selectedRows.length > 1 ? "s" : ""} ?`, 
                                        handleYes : () => handleDelete() 
                                }))}
                                sx={{
                                    ...button_sx, 
                                    marginLeft : "10px", 
                                    backgroundColor: '#f54242', // Couleur de fond normale
                                    '&:hover': {
                                      backgroundColor: '#B71C1C' // Couleur de fond au survol
                                    },
                                }}
                            >
                                Supprimer
                            </Button>
                        </div>
                        <MuiSearchBox 
                            search_string={manager.search_string}
                            setSearchString={(search_string) => setManager(state => {return {...state, search_string}})}
                        />
                    </div>
                    <div className = "suppliers__container">
                        <DataGridPro
                            loading={manager.load}
                            rows={filter_custom(data.rows)}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                            rowHeight={40}
                            checkboxSelection
                            //disableColumnMenu={true} 
                            disableColumnResize={true}
                            hideFooter={true}
                            editMode="row"
                            onRowSelectionModelChange={handleSelectionChange}
                            onRowDoubleClick={(row) => handleDoubleClick(row.row)}
                            getRowId={(row) => row._id}
                            columns={data.columns.map(column => {
                                return {
                                    ...column,
                                    editable: false,
                                    flex: 1, // Donne une flexibilité égale à chaque colonne
                                }
                            }) as any}
                            sx={{
                                ...data_grid_x_sx, 
                                border : "none!important",
                                "& .MuiFormControl-root, .MuiInputBase-root, .MuiInputBase-root input" : {
                                    height : '100%',
                                    justifyContent : 'center'
                                }, 
                                "& .MuiInputBase-input, .MuiFormControl-root" : {
                                    padding : 0, 
                                },
                                "& .MuiInputBase-input" : {
                                    paddingLeft : "10px", 
                                    width : "calc(100% - 10px)", 
                                },
                                "& .MuiDataGrid-cell--textLeft *" : {
                                    fontSize : "0.875rem"
                                }, 
                                ".MuiDataGrid-cell:focus" : {
                                    outline : "none!important"
                                }
                            }} 
                        />
                        {
                            (filter_custom(data.rows).length === 0 && manager.initialize) ? 
                                <NoDataGrid />
                            : 
                                null
                        }
                    </div>

                </BaseContainer>
        
            </BaseApp>



        </React.Fragment>
    )
}