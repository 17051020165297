import {motion} from 'framer-motion' ; 
import {Link, useLocation} from 'react-router-dom'
import { useSelector } from "react-redux";
import { account_user } from '../../store/account/selector.account';
import { navigation_data } from './data.nav';
import { ServerProps } from '../../config/server.config';
import { Icons } from 'tc-minibox';
import { ElectricalServicesRounded } from '@mui/icons-material';





export default function Navigation(props:ServerProps) {
    const account = useSelector(account_user)
    ////////////// VARIABLES //////////////
    const location = useLocation()

    return (
        <motion.div 
            exit = "exit"
            animate = "animate"
            initial = "initial"
            className="navigation">

            {/* /// LOGO /// */}
            <div className="menu-icon">
                <Icons name = "bars" mode = "duotone" color = "#116633" />
            </div>
            {/* /// LISTING NAVIGATION /// */}

            <div 
                className="navigation__container"
            >
                {
                    navigation_data({account : account, server : props.server}).map(el => (
                        <div 
                            key = {el.id}
                        >
                            <Link className={location.pathname === el.path ? "active" : ""} to = {el.path} >
                                <div>{el.svg}</div>
                                <p><span>{el.content}</span></p>
                            </Link>
                        </div>
                    ))
                }
                <div>
                    <a href={`https://v2.myogec.com/index.php?token=${account.infos.token_session}`} target='_blank' rel='noreferrer'>
                        <ElectricalServicesRounded sx={{color : "rgb(17, 102, 51)"}}/>
                        <p><span>MyOGEC v2</span></p>
                    </a>
                </div>
            </div>
        </motion.div>
    )
}
