import React, { useCallback, useEffect, useRef, useState } from 'react';
import { server_host, ServerProps } from '../../config/server.config';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import StepsText from '../../tools/components/general/StepsText';
import { useDispatch, useSelector } from 'react-redux';
import { Icons, StringUtils, URLUtils } from 'tc-minibox';
import { useLocation } from 'react-router-dom';
import { addRequestStatus, set_popup_warning, setLoaderStatus } from '../../store/global/actions.global';
import { account_user } from '../../store/account/selector.account';
import { ListingElementStructure } from './documents.utils';
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import {Table} from 'tc-minibox'
import PreviewFile from './widgets/PreviewFile';
import NewFolder from './widgets/NewFolder';
import { useDropzone } from 'react-dropzone';
import {ReactComponent as DragOn} from '../../assets/svg/icons/drag_on.svg' ; 
import NewFile from './widgets/NewFile';
import RenameElement from './widgets/RenameElement';
import MoveElement from './widgets/MoveElement';

export interface DocumentsProps extends ServerProps {
  
}

export interface AffairManager {
    initialize : boolean, 
    preview : string | null, 
    parent_id : ListingElementStructure | null
    selected_folder : ListingElementStructure | null, 
    selected_affair : string | null, 
    new_folder : boolean, 
    new_file : boolean,
    rename : ListingElementStructure | null, 
    move : string | null
}

export default function Documents(props : DocumentsProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params:string|null = searchParams.get('id_affair');
    const account = useSelector(account_user)
    const blocked_folders = ["invoices"]
    const blocked_files = ["costestimate"]

    // * STATES 
    const [affairs, setAffairs] = useState<any>([])
    const [content, setContent] = useState<any>([])
    const [manager, setManager] = useState<AffairManager>({ 
        initialize : false, 
        preview : null,
        selected_affair : null, 
        selected_folder : null, 
        parent_id : null, 
        new_folder : false, 
        new_file : false, 
        rename : null, 
        move : null
    })


    const parsed_affairs:ListingElementStructure[] = affairs.map((af:any) => {
        //const bm = direction.find(d => d.id_user === af.business_manager)?? {firstname : "Introuvable", lastname : ""}
        return {
            _id : af._id,
            name : `${af.affair_number} - ${af.reference}`,
            type : "folder", 
            number_of_elements : af.elements_count !== null ? Object.values(af.elements_count).reduce((a:any, b:any) => a + b, 0) : 0,
            user : `${af.id_user.firstname} ${af.id_user.lastname}`,
            date_insert : af.date_insert,
            last_modified : af.last_modified
        }
    })

    const parsed_content:ListingElementStructure[] = content.map((af:any) => {
        //const bm = direction.find(d => d.id_user === af.business_manager)?? {firstname : "Introuvable", lastname : ""}
        return {
            _id : af._id,
            name : af.name,
            type : af.type, 
            number_of_elements : af.number_of_elements !== null ? af.number_of_elements : "-",
            user : af.user,
            date_insert : af.date_insert,
            last_modified : af.last_modified
        }
    })

    const tbody = manager.selected_affair === null ? parsed_affairs : parsed_content
    //const tbody = parsed_affairs

    // * IMPORT DOCS
    const fileInputRef = useRef(null)
    const onDrop = useCallback((acceptedFiles:File[]) => {
        //props.handleSubmit(props.multiple ? acceptedFiles : acceptedFiles[0])
        if(manager.selected_affair === null) {
            return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas importer de fichiers à ce niveau."}))
        }

        if(!manager.selected_folder?.can_delete && manager.selected_folder !== null) {
            return dispatch(addRequestStatus({status : false, message : "Ce dossier à lié à un module de l'application. Vous ne pouvez pas importer de fichiers ici."}))
        }

        const formContainer = new FormData()
        formContainer.append('id_affair', manager.selected_affair)
        formContainer.append('id_folder', manager.selected_folder !== null ? manager.selected_folder._id : "")
        formContainer.append('user', account.infos._id)
        acceptedFiles.forEach((file:any) => {
            formContainer.append('files', file)
        })

        dispatch(setLoaderStatus({status : true, message : "Téléchargement des fichiers..."}))
        props.server.post('/documents/import', formContainer, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(res => {
            setContent((state:any) => {
                return [...state, ...res.data.data]
            })
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })

    }, [props, manager])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})

    // * FUNCTIONS
    const handleDelete = (data:any) => {
        const filtered_data = data.filter((dt:any) => dt.can_delete)
        const ids_to_delete = filtered_data.map((dt:any) => dt._id)
        dispatch(setLoaderStatus({status : true, message : "Suppression en cours..."}))
        props.server.post('/documents/temporary-delete', {ids_to_delete : ids_to_delete})
        .then(res => {
            setContent((state:any) => {
                return state.filter((dt:any) => !ids_to_delete.includes(dt._id))
            })
            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        
    }

    //* USE EFFECTS 
    useEffect(() => {
        if(!manager.initialize && manager.selected_affair === null && manager.selected_folder === null) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            props.server.get('/documents/initialize', {
                headers: {
                    Authorization: `Bearer ${account.token}`
                }
            })
            .then(res => {
                setAffairs(res.data.data)
                setManager(state => {return{...state, initialize : true }})

                if(!StringUtils.isUndefinedValue(id_affair_params)){
                    setManager(state => {return{...state, selected_data : id_affair_params !== null ? parseInt(id_affair_params) : null}})
                    URLUtils.removeURLParameter('id_affair')
                } 
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }

        if(!manager.initialize && manager.selected_affair !== null && manager.selected_folder === null) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))
            const affair_selected = affairs.find((af:any) => af._id === manager.selected_affair)
            props.server.post('/documents/get-affair-content', {id_folder : manager.selected_folder, id_affair : manager.selected_affair, is_default_setting : affair_selected.is_default_setting?? false})
            .then((res) => {
                setContent(res.data.data) 
                setManager(state => {return{...state, initialize : true}})

                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))

                setManager(state => {return{...state, selected_affair : null, selected_folder : null, initialize : true}})
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }

        if(!manager.initialize && manager.selected_affair !== null && manager.selected_folder !== null) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des données en cours ..."}))

            props.server.post('/documents/get-folder-content', {id_folder : manager.selected_folder._id, id_affair : manager.selected_affair})
            .then((res) => {
                
                setContent(res.data.data) 
                // ! SUPPRIMER ID_AFFAIR NULL UNE FOIS DEV TERMINÉ
                setManager(state => {return{...state, initialize : true}})

                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(setLoaderStatus({status : false}))

                setManager(state => {return{...state, selected_affair : null, selected_folder : null, initialize : true}})
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
        
    }, [manager])

    // * FUNCTIONS 
    const handleDownloadFile = (data:ListingElementStructure) => {
        const seleted_data = content.find((c:any) => c._id === data._id)
        const link = document.createElement('a');
        link.href = server_host(seleted_data.url);
        const urlWithoutQuery = seleted_data.url.split('?')[0];
        const extension = urlWithoutQuery.split('.').pop();
        link.download = `${data.name}.${extension}`; // Nom du fichier à télécharger
        link.target = '_blank';
        // Ajouter le lien au DOM et le déclencher
        document.body.appendChild(link);
        link.click();

        // Supprimer le lien du DOM une fois le téléchargement terminé
        document.body.removeChild(link);
    }
    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 40, 
        editing : false, 
        icons : {
            mode : "duotone", 
            color : "#2A6118"
        }, 
        config : [
            // *
            {
                info : {
                    text : "Nom",
                    name : "name", 
                    custom : (tr:any) => {
                        return (
                            <div id = "name">
                                {
                                    tr.type === "folder" ? 
                                    <Icons name = "folder" mode = "duotone" color = "#1f854d"/> : 
                                    <Icons name = "file-invoice-dollar" mode = "duotone" color = "#1f854d"/>
                                }
                                <p>{tr.name}</p>
                            </div>
                        )
                    }
                }, 
 
                style : {
                    width : "calc(100% - 490px)", 
                    tbody : {
                        textAlign : "start"
                    }
                }
            }, 
            {
                info : {
                    text : "Nb d'éléments", 
                    name : "number_of_elements", 
                }, 
                style : {
                    width : "150px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Date d'ajout", 
                    name : "date_insert", 
                    type : "date"
                }, 
                style : {
                    width : "150px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            {
                info : {
                    text : "Dern. modification", 
                    name : "last_modified", 
                    type : "date"
                }, 
                style : {
                    width : "190px", 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
        ], 
        data : tbody,
        setData : setAffairs,
        header : {
            custom : (data:any) => {
                var selected_data:any = []

                if(manager.selected_affair === null) {
                    selected_data = data.map((d:any) => affairs[d])
                }

                if(manager.selected_affair !== null) {
                    selected_data = data.map((d:any) => content[d])
  
                }


                return (
                    <div className='documents__custom-header'>
                        {
                            manager.selected_affair !== null ? 
                                <div id = "return" onClick = {() => {
                                    if(manager.selected_folder === null) {
                                        return setManager(state => {return{...state, selected_affair : null, selected_folder : null, parent_id : null, initialize : false}})
                                    }

                                    setManager(state => {return{...state, selected_folder : manager.parent_id, parent_id : manager.selected_folder === null ? null : (manager.selected_folder.parent_id?.parent_id as any),  initialize : false}})
                                    //const selected_folder_data = content.find((c:any) => c._id === manager.selected_folder)
                                    //setManager(state => {return{...state, selected_folder : selected_folder_data.parent_id}})

                                }}>
                                    <Icons name = "arrow-turn" mode = "duotone" color = "#007aff"/>
                                </div>
                            : 
                                null
                        }

                        {
                            !blocked_folders.includes(`${manager.selected_folder?._id}`) && manager.selected_affair !== null ?
                                <React.Fragment>
                                    <div id='add'>
                                        <Icons name = "plus" mode = "duotone" color = "#1f854d"/>
                                        <div id = "add--menu">
                                            <div 
                                                id = "folder" 
                                                onClick = {() => setManager(state => {return{...state, new_folder : true}})}
                                            >
                                                <p>Nouveau dossier</p>
                                            </div>
                                            <div id = "file" onClick = {() => setManager(state => {return{...state, new_file : true}})}>
                                                <p>Nouveau fichier</p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        selected_data.filter((dt:any) => dt !== undefined).filter((dt:any) => dt.can_delete).length > 0 ?
                                            <React.Fragment>
                                                <div 
                                                    id = "trash"
                                                    onClick = {() => {
                                                        if(selected_data.length === 0) return dispatch(addRequestStatus({status : false, message : "Veuillez sélectionner au moins une élément."}))
                                                        if(selected_data.map((dt:any) => dt.can_delete).includes(false)) return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas supprimer des éléments issus d'autres modules."}))
            
                                                        return dispatch(set_popup_warning({
                                                            status : true, 
                                                            text : selected_data.length > 1 ? `Voulez-vous supprimer les ${selected_data.length} éléments sélectionnés ?` : `Voulez-vous supprimer le ${selected_data[0].type === "folder" ? "dossier" : "fichier"} "${selected_data[0].name}" ?`, 
                                                            handleYes : () => handleDelete(selected_data)
                                                        }))
                                                        
                                                    }}
                                                >

                                                    <Icons name = "trash" mode = "duotone" color = "red"/>
                                                </div>

                                                <div 
                                                    id = "rename"
                                                    onClick={() => {
                                                        setManager(state => {return{...state, rename : selected_data[0]}})
                                                    }}
                                                >
                                                    Renommer
                                                </div>

                                                <div 
                                                    id='move'
                                                    onClick={() => setManager(state => {return{...state, move : selected_data[0]._id}})}
                                                >
                                                    Déplacer
                                                </div>
                                            </React.Fragment>
                                        : 
                                            null
                                    }

                                </React.Fragment>      
                            : 
                                null
                        }

                    </div>
                )
            }
        }, 
        handleDoubleClick : (data:ListingElementStructure) => {
            if(data.type === "file") {
                const seleted_data = content.find((c:any) => c._id === data._id)
                if(seleted_data.url) {
                    const urlWithoutQuery = seleted_data.url.split('?')[0];
                    const extension = urlWithoutQuery.split('.').pop();

                    if(extension === "pdf") {
                        return setManager(state => {return{...state, preview : extension === "pdf" ? seleted_data.url : null}})
                    }
                    return handleDownloadFile(data)

                }
                return dispatch(addRequestStatus({status : false, message : "Le fichier n'a pas de lien de téléchargement."}))

            }

            if(manager.selected_affair === null) {
                return setManager(state => {return{...state, selected_affair : data._id, initialize : false}})
            }

            const selected_folder_data = content.find((c:any) => c._id === data._id)

            return setManager(state => { 
                return{...state, parent_id : manager.selected_folder, selected_folder : {...selected_folder_data, parent_id : manager.selected_folder}, initialize : false}
            })
        }
    }

    return (
        <React.Fragment>
            {
                manager.move !== null ?  
                    <MoveElement 
                        server={props.server}
                        handleClose = {() => setManager(state => {return{...state, move : null}})}
                    />
                : 
                    null
            }
            {
                manager.rename !== null ?
                    <RenameElement 
                        server={props.server}
                        element = {manager.rename}
                        setContent={setContent}
                        handleClose = {() => setManager(state => {return{...state, rename : null}})}
                    />
                : 
                    null
            }

            {
                manager.preview !== null ? 
                    <PreviewFile 
                        url = {manager.preview}
                        handleClose = {() => {
                            setManager(state => {return{...state, preview : null}})
                        }}
                    />
                : 
                    null
            }
            {
                manager.new_folder ? 
                    <NewFolder 
                        server={props.server}
                        setData={setContent}
                        handleClose = {() => setManager(state => {return{...state, new_folder : false}})}
                        current_folder = {manager.selected_folder}
                        current_id_affair = {manager.selected_affair?? null}
                    />
                : 
                    null
            }
                        {
                manager.new_file ? 
                    <NewFile
                        server={props.server}
                        setData={setContent}
                        handleClose = {() => setManager(state => {return{...state, new_file : false}})}
                        current_folder = {manager.selected_folder}
                        current_id_affair = {manager.selected_affair?? null}
                    />
                : 
                    null
            }

            <BaseApp>
                <BaseContainer nameClass='documents'>
                    <StepsText 
                        title = "Documents"
                        position = {{
                            x : 40, 
                            y : 0
                        }}
                    />
                    
                    <div className='documents__container' {...getRootProps()}>
                        <Table {...table_config} />

                        {
                            isDragActive && manager.selected_affair !== null ?
                                <div className='documents--dragmode'>
                                    <DragOn />
                                </div>
                            : null
                        }
 
                        <input 
                            type = "file"
                            ref={fileInputRef} 
                            {...getInputProps()}
                        />
                    </div>

                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}