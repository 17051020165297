import { Search } from "@mui/icons-material";
import { IconButton, InputBase, Paper } from "@mui/material";
import React, { ChangeEvent, useState } from "react";

export interface MuiSearchBoxProps {
    search_string : string, 
    setSearchString : (search_string : string) => void
}

export default function MuiSearchBox(props:MuiSearchBoxProps) {

    return (
        <Paper
            component="form" 
            className="mui-search-box"
            sx = {{
                height : "35px", 
                '& *' : {
                color : `grey!important`
                } 
            }}
        >
            <IconButton type="button" sx={{ p: '0px 2.5px' }} aria-label="search">
                <Search />
            </IconButton>
            <InputBase
                sx={{ flex: 1 }}
                placeholder="Rechercher un client..."
                inputProps={{ 'aria-label': 'Rechercher un client...' }}
                value={props.search_string}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const {
                    target: { value },
                    } = event;
                    props.setSearchString(value);
                }}
            />

        </Paper>
    )
}