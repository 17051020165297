import React, { useEffect, useRef, useState } from "react";
import { ServerProps } from "../../../../../config/server.config";
import BasePopup from "../../../../../tools/components/general/BasePopup";
import SearchSelect from "../../../../../tools/components/general/SearchSelect";
import {ReactComponent as SelectSVG} from "../../../../../assets/svg/illustrations/select__illustration.svg"
import { Icons } from "tc-minibox";
import MainButton from "../../../../../tools/components/general/MainButton";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../../store/global/actions.global";



export interface ReassignmentManager extends ServerProps{
    handleClose : () => void, 
    selected_data : any, 
    setData : React.SetStateAction<any>
}


export default function Reassignment(props:ReassignmentManager) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [newAffair, setNewAffair] = useState<any>({
        id : null, 
        infos : {}
    })

    // * REFS
    const oldScrollRef = useRef<HTMLDivElement>(null);
    const newScrollRef = useRef<HTMLDivElement>(null);

    // * USE EFFECTS
    useEffect(() => {
        const syncScroll = (e: Event, otherRef: React.RefObject<HTMLDivElement>) => {
            if (otherRef.current && e.target instanceof HTMLElement) {
                otherRef.current.scrollTop = e.target.scrollTop;
            }
        };

        const oldScroll = oldScrollRef.current;
        const newScroll = newScrollRef.current;

        if (oldScroll && newScroll) {
            oldScroll.addEventListener("scroll", (e) => syncScroll(e, newScrollRef));
            newScroll.addEventListener("scroll", (e) => syncScroll(e, oldScrollRef));
        }

        return () => {
            if (oldScroll && newScroll) {
                oldScroll.removeEventListener("scroll", (e) => syncScroll(e, newScrollRef));
                newScroll.removeEventListener("scroll", (e) => syncScroll(e, oldScrollRef));
            }
        };
    }, [newAffair.id]);

    // * FUNCTIONS
    const handleSubmit = () => {
        const data = {
            new_affair : newAffair.id, 
            inv_ids : props.selected_data.map((st:any) => st._id), 
            doc_ids : props.selected_data.map((st:any) => st.id_doc)
        }

        props.server.post('/invoicing/reassign', data)
        .then(res => {
            props.handleClose()
            props.setData((state:any) => {
                return state.map((st:any) => {
                    if(props.selected_data.map((sd:any) => sd._id).includes(st._id)) {
                        
                        return {
                            ...st, 
                            id_affairs : [{
                                ...st.id_affairs[0],
                                id : newAffair.infos
                            }]
                        }
                    }
                    return st
                })

            })

            dispatch(addRequestStatus(res.data))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    return (
        <BasePopup nameClass = "reassignment" handleClose={() => props.handleClose()}>
            <div className="reassignment--newAffair">
                <label htmlFor=''>Choisissez une nouvelle affaire </label>
                <SearchSelect
                    {...{

                        text_no_data : "Aucune affaire trouvée", 
                        handleClick : (data) => {
                            setNewAffair({
                                id : data.id, 
                                infos : data.infos
                            })
                        },
                        handleDelete : () => setNewAffair(null),
                        no_data_text : "Aucune affaire trouvée", 
                        server : {
                            axios : props.server,
                            route : "/invoicing/search-affairs"
                        },
                        value : newAffair
                    }}
                />
            </div>

            {
                newAffair.id !== null ? 
                    <React.Fragment>
                        <div className="reassignment__result">
                            <div className="reassignment__result--old" ref={oldScrollRef}>
                                {
                                    props.selected_data.map((st:any, index:number) => (
                                        <div key = {`REASSIGN-RESULT-OLD-${index}`}>
                                            <div id = "code"><p>{`${st.id_affairs[0].id.affair_number} - ${st.id_affairs[0].id.reference}` }</p></div>
                                            <div id = "info"><p>{`${st.inv_number} - ${st.id_supplier.name}`}</p></div>
                                            <div id="doc_number">{st.doc_number}</div>
                                        </div>
                                    ))
                                }

                            </div>
                            <div className="reassignment__result--arrow">
                                <Icons name = "arrow-down" />
                            </div>
                            <div className="reassignment__result--new" ref={newScrollRef}>
                                {
                                    newAffair.id !== null ? 
                                        props.selected_data.map((st:any, index:number) => (
                                            <div key = {`REASSIGN-RESULT-NEW-${index}`}>
                                                <div id = "code"><p>{`${newAffair.infos.affair_number} - ${newAffair.infos.reference}` }</p></div>
                                                <div id = "info"><p>{`${st.inv_number} - ${st.id_supplier.name}`}</p></div>
                                                <div id="doc_number">{st.doc_number}</div>
                                            </div>
                                        ))
                                    
                                    : 
                                        <div className="reassignment__result--noSelectedAffair">
                                            <SelectSVG />
                                            <div>Aucune affaire sélectionnée</div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="reassignment__result--button">
                            <MainButton handleClick={handleSubmit} >Réassigner</MainButton>
                        </div>
                    </React.Fragment>
                : 
                    null
            }
        </BasePopup>
    )
}