import {ReactComponent as PersonalFilesSVG} from '../assets/svg/illustrations/personal-files-bro.svg'


export default function NoDataGrid() {
    return (
        <div className="no-data-grid">
                <PersonalFilesSVG />
                <p className="text-gray-500">Aucun résultat</p>
        </div>
    )
}