/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react' ; 
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addRequestStatus} from '../../store/global/actions.global';
import { ServerProps } from '../../config/server.config';
import { StringUtils, URLUtils } from 'tc-minibox';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { DataType} from 'tc-minibox/dist/types/table/index.table';
import ListingInv from './layouts/Listing';
import StepsText from '../../tools/components/general/StepsText';
import PaymentDetail from './layouts/PaymentDetail';

interface PaymentsProps extends ServerProps {

}

export interface PaymentsManager {
    initialize : boolean, 
    mode : "listing", 
    deposit : boolean, 
    selected_data : number | null, 
    load_data_grid : boolean
}

export default function Payments(props:PaymentsProps) {
    const dispatch = useDispatch()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params = searchParams.get('id_affair');

    const [manager, setManager] = useState<PaymentsManager>({
        initialize : false, 
        mode : "listing", 
        deposit : false,
        selected_data : null, 
        load_data_grid : false
    })

    const [invoices, setInvoices] = useState<DataType>([])

    const handleChangeManager = (name:string, val:any) => setManager(state => {return{...state, [name] : val}})

    useEffect(() => {
        document.title = "Règlements fournisseurs - MyOGEC";
    }, []);

    useEffect(() => {
        if(!manager.initialize) {
            setInvoices([])
            setManager(state => { return {...state, load_data_grid : true}})
            props.server.get('/payments/listing')
            .then(res => {
                setManager(state => { return {...state, initialize : true}})
                const data = res.data.data.map((dt:any) => {
                    return {
                        ...dt, 
                        dates : Object.fromEntries(Object.entries(dt.dates).map(([key, value]) => [key, new Date(value as string)]))
                    }
                })
                setInvoices(data)
                if(id_affair_params !== null) {
                    const parsedValue = parseInt(id_affair_params);
                    
                    // Check if parsedValue is not undefined
                    if(!StringUtils.isUndefinedValue(parsedValue)) {
                        setManager(state => { return {...state, selected_data: parsedValue} });
                
                        // Assuming removeURLParameter expects a string, don't pass null
                        URLUtils.removeURLParameter('id_affair');
                    }
                }
                
                setManager(state => { return {...state, load_data_grid : false}})
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                handleChangeManager("initialization", "resolve")
                setManager(state => { return {...state, load_data_grid : false}})
            })
        }
    }, [manager.initialize])


    return (
        <React.Fragment>
                    {
                        manager.selected_data !== null ? 

                                <PaymentDetail
                                    setManager={setManager}
                                    server={props.server}
                                    handleClose = {() => setManager(state => {return {...state, selected_data : null}})}
                                    data = {manager.selected_data}
                                    setData = {(data:any) => setInvoices(state => {
                                        return state.map((el:any) => {
                                            if((el._id as any) === (manager.selected_data as any)._id) {
                                                return {
                                                    ...el, 
                                                    ...data
                                                }
                                            }
                                            return el
                                        })
                                    })}

                                />

                        : 
                            null
                    }


            <BaseApp>
                <BaseContainer nameClass="payments">
                    <StepsText
                        title = "Règlements"
                        position = {{
                            x : 40, 
                            y : 0
                        }}
                    />
                    <ListingInv 
                        server={props.server}
                        data = {invoices}
                        setData={setInvoices}
                        manager={manager}
                        handleAdd = {() => handleChangeManager('deposit', true)}
                        setManager={setManager}
                        load_data_grid={manager.load_data_grid}
                    />
                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}