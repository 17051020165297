export const type_payments = [
    {
        id : "TYPE-PAYMENTS-01", 
        name : "check", 
        text : "Chèque"
    }, 
    {
        id : "TYPE-PAYMENTS-02", 
        name : "bank_card", 
        text : "Carte bancaire"
    }, 
    {
        id : "TYPE-PAYMENTS-03", 
        name : "treaty", 
        text : "Lettre de change"
    }, 
    {
        id : "TYPE-PAYMENTS-04", 
        name : "bank_transfer", 
        text : "Virement"
    }, 
    {
        id : "TYPE-PAYMENTS-05", 
        name : "cash", 
        text : "Espèces"
    }, 
    {
        id : "TYPE-PAYMENTS-06", 
        name : "lcr", 
        text : "LCR automatique"
    }, 
    {
        id : "TYPE-PAYMENTS-07", 
        name : "bank-direct-debit", 
        text : "Prélèvement"
    }, 
    {
        id : "TYPE-PAYMENTS-08", 
        name : "project-owner", 
        text : "Maître d'ouvrage"
    }, 

]


export const green_style_type_payments = [
    "treaty", 
    "lcr", 
    "bank-direct-debit", 
    "check", 
    "project-owner"
]

export const contacts_types = [
    {
        id : "CONTACTS-TYPE-01",
        name : "phone_number",
        text : "Téléphone", 
        regex : /^[0-9]{10}$/gm, 
        placeholder : "Ex: 0123456789",
        error : "Le numéro de téléphone doit être composé de 10 chiffres"
    }, 
    {
        id : "CONTACTS-TYPE-02",
        name : "mail",
        text : "Mail", 
        placeholder : "Ex: marcdupont@example.fr", 
        regex : /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/gm, 
        error : "Le format de l'adresse mail est invalide"
    }, 
    {
        id : "CONTACTS-TYPE-03",
        name : "other",
        text : "Autres", 
        placeholder : "",
        regex : /^[\s\S]+$/gm,
    }, 

]

export const paymentDeadlines = [
    { id: 1, name: 0, text: 'À réception de la facture' },
    { id: 1, name: 1, text: '30j' },
    { id: 2, name: 2, text: '30j (fin de mois)' },
    { id: 5, name: 5, text: '45j' },
    { id: 6, name: 6, text: '45j (fin de mois)' },
    { id: 3, name: 3, text: '60j' },
    { id: 4, name: 4, text: '60j (fin de mois)' },
];
  

