import { Document, Page, pdfjs } from "react-pdf";
import BasePopup from "../../../../../../tools/components/general/BasePopup";
import { useState } from "react";

export interface EditPagesCorrespondanceProps {
    data : any, 
    handleClose: () => void;    
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();

export default function EditPagesCorrespondance(props: EditPagesCorrespondanceProps) {
    const url = props.data.url
    const [numPages, setNumPages] = useState<number | null>(null);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    };
    return (
        <BasePopup 
            nameClass="edit-pages--popup"
            handleClose = {props.handleClose}
        >
            <Document file={"https://file-examples.com/storage/fedab560656679c849d6def/2017/10/file-sample_150kB.pdf"} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
        </BasePopup>
    )
}

