/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { NavigationProps } from "../nav/data.nav";
import { useDispatch, useSelector } from "react-redux";
import { account_user } from "../../store/account/selector.account";
import { useEffect, useState } from "react";
import { StringUtils, URLUtils } from "tc-minibox";
import { addRequestStatus, setLoaderStatus, set_popup_warning } from "../../store/global/actions.global";
import { DataType } from "tc-minibox/dist/types/table/index.table";
import BaseApp from "../../tools/components/general/BaseApp";
import Detail, { DetailProps } from "./detail/Detail";
import StepsText from "../../tools/components/general/StepsText";
import { analytic_codes } from "./utils.affairs";
import { DataGridPro, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { frFR } from "@mui/x-data-grid/locales";
import { data_grid_x_sx } from "../../config/mui_style.config";
import moment from "moment";
import { Button, Tab, Tabs } from "@mui/material";
import { AddCircleRounded, DeleteRounded } from "@mui/icons-material";
import { button_sx } from "../../config/mui_style.config";
import { Box } from "@mui/system";
import MuiSearchBox from "../../templates/MuiSearchBox";
import NoDataGrid from "../../templates/NoDataGrid";

export interface AffairManager {
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : number | null, 
    filter : "all" | number, 
    search_string : string, 
    load_data_grid : boolean
}

export default function Affairs(props:NavigationProps) {
    // * URL PARAMS && REDUX
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const id_affair_params:string|null = searchParams.get('id_affair');
    const navigate = useNavigate()
    //const id_amendment_params = searchParams.get('id_amendment');
    const dispatch = useDispatch()
    const account = useSelector(account_user)

    // * STATES
    const [manager, setManager] = useState<AffairManager>({ initialize : false, mode : "listing", filter : "all", search_string : "", load_data_grid : false })
    const [affairs, setAffairs] = useState<DataType>([])
    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    // * USE EFFECTS
    useEffect(() => {
        document.title = "Affaires - MyOGEC";
    }, []);
    
    useEffect(() => {
        if(!manager.initialize) {
            setManager(state => {return{...state, load_data_grid : true }})
            props.server.get('/affairs/get-affairs-list', {
                headers: {
                    Authorization: `Bearer ${account.token}`
                }
            })
            .then(res => {
                setManager(state => {return{...state, mode : "listing" }})
                setAffairs(res.data.data)

            
                setManager(state => {return{...state, initialize : true }})

                if(!StringUtils.isUndefinedValue(id_affair_params)){
                    setManager(state => {return{...state, selected_data : id_affair_params !== null ? parseInt(id_affair_params) : null}})
                    URLUtils.removeURLParameter('id_affair')
                } 
                setManager(state => {return{...state, load_data_grid : false }})
            })
            .catch(err => {
                setManager(state => {return{...state, load_data_grid : false }})
                dispatch(addRequestStatus({
                    status : false, 
                    message : "Erreur interne. Veuillez réessayer. "
                }))
            })
        }
    }, [manager.initialize])

    // * VARIABLES 
    const tbody = affairs.map(af => {
        return {
            ...af, 
            business_manager : `${af.manager.firstname} ${af.manager.lastname}`
        }
    })

    const filter_config = [
        {
            name : "all", 
            title : "Tous",
        }, 
        ...analytic_codes
    ]
    // * FUNCTIONS 
    const filter_custom = (rows:any[]) => {
        var cleaned_data:any = rows.map((row) => {
            return {
                ...row,
                search_string: `${row.affair_number} ${moment(row.costestimate_date).format('DD/MM/YYYY')} ${row.total_ht.$numberDecimal} ${row.total_tva.$numberDecimal} ${row.total_ttc.$numberDecimal} ${row.object} ${row.reference} ${row.type} ${row.business_manager}`.replaceAll(" ", "-"),
            };
        })

        const search_string = manager.search_string.toLowerCase().replaceAll(" ", "");  // Assumé depuis votre gestionnaire de recherche
        const matchData = cleaned_data.filter((da:any) => {
            const match = da.search_string.match(new RegExp(search_string, "i"));  // Utilise RegExp pour la correspondance insensible à la casse
            return match != null;
        }).sort((a:any, b:any) => {
            const matchA = a.search_string.match(new RegExp(search_string, "i"));
            const matchB = b.search_string.match(new RegExp(search_string, "i"));
    
            // Calculer le nombre de correspondances (bien que match retourne juste la première correspondance, donc ce sera toujours 1 ou 0 dans ce cas)
            const countA = matchA ? matchA.length : 0;
            const countB = matchB ? matchB.length : 0;
    
            // Trier en fonction du nombre de correspondances (du plus élevé au plus bas)
            if (countA > countB) {
                return -1; // a avant b
            } else if (countA < countB) {
                return 1; // b avant a
            } else {
                return 0; // aucun changement d'ordre
            }
        });
        
        var result = rows.filter((da:any) => matchData.some((md:any) => md._id === da._id))

        if(manager.filter !== "all") {
            result = result.filter((da:any) => da.type === manager.filter)
        }

        return result
        
    }

    const handleDelete = (e:any[]) => {
        if(e.length > 0) {
            dispatch(setLoaderStatus({status : true, message : "Suppression des données en cours ..."}))
            const selected_data = e.map(da => da._id)
            props.server.post('/affairs/delete', selected_data)
            .then(res => {
                
                setAffairs(state => state.filter(st => !selected_data.includes(st._id)))
                dispatch(addRequestStatus(res.data))
                dispatch(setLoaderStatus({status : false}))
            })
            .catch(err => {
                dispatch(addRequestStatus(err.response.data))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    } 

    const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
        setSelectedRows(newSelection as any);
    };

    // * COMPONENTS CONFIG
    const detail_config:DetailProps = {
        manager : manager, 
        setManager : setManager, 
        server : props.server, 
        selected_data : affairs.find(af => af._id === manager.selected_data)
    }

    const data = {
        columns : [
            { field: 'affair_number', headerName: 'N°', minWidth: 80, align: 'center' },
            { field: 'reference', headerName: 'Référence',  minWidth: 250},
            { field: 'object', headerName: 'Objet', minWidth: 200 },
            { 
                field: 'costestimate_date', 
                headerName: 'Date', 
                minWidth: 150, 
                align: 'center', 
                renderCell: (params:any) => {
                    const tr = params.row;
                    return (
                        `${moment(tr.costestimate_date).format("DD/MM/YYYY")}`
                    );
                },
            },
            { field: 'type', headerName: 'Type', minWidth: 100, align: 'center' },
            { field: 'business_manager', headerName: 'Responsable dossier', minWidth: 250, align: 'center' },
        ], 
        rows : tbody
    }

    return (
        <BaseApp>
            <BaseContainer nameClass="affairs">
                <StepsText 
                    title = "Affaires"
                    position = {{
                        x : 60, 
                        y : 0
                    }}
                />
                {
                    manager.mode === "detail" ? 
                        <Detail {...detail_config} />
                    : 
                        <div className="affairs__listing">
                            <div
                                className="affairs__listing--header"
                            >
                            <div id="tools">
                                    <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/new')}
                                            startIcon={<AddCircleRounded />}
                                            sx={{
                                                ...button_sx,  
                                                backgroundColor: '#2196f3', // Couleur de fond normale
                                                '&:hover': {
                                                backgroundColor: '#0d47a1' // Couleur de fond au survol
                                                },
                                            }}
                                        >
                                            Ajouter
                                    </Button>

                                    {
                                        selectedRows.length > 0 ? 
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<DeleteRounded />}
                                            onClick={() => {
                                                const rowsContent = selectedRows.map((row) => data.rows.find((r:any) => r._id === row))
                                                if(rowsContent.map((dt:any) => dt.is_default_setting).includes(true)) return dispatch(addRequestStatus({status : false, message : "Vous ne pouvez pas supprimer les affaires définies par défaut. Veuillez vous rendre dans les paramètres pour effectuer cette action."}))
                                                    return dispatch(set_popup_warning({
                                                        status : true, 
                                                        text : `Êtes-vous sûr de vouloir supprimer ${selectedRows.length > 1 ? "ces affaires" : "cette affaire"} ?`, 
                                                        handleYes : () => handleDelete(rowsContent)
                                                    })) 
                                                }
                                            }
                                            sx={{
                                                ...button_sx, 
                                                marginLeft : "5px", 
                                                backgroundColor: '#f54242', // Couleur de fond normale
                                                '&:hover': {
                                                    backgroundColor: '#B71C1C' // Couleur de fond au survol
                                                },
                                            }}
                                        >
                                            Supprimer
                                        </Button>
                                        : 
                                            null
                                    }
                                </div>
                                <div className={`filters filters--${manager.filter}`}>
                                <Box sx={{ }}>
                                    <Tabs
                                        value={manager.filter}
                                        onChange={(e, newValue) => setManager(state => {return{...state, filter : newValue}})}
                                        textColor="secondary"
                                        indicatorColor="secondary"
                                        aria-label="secondary tabs example"
                                    >
                                        {
                                            filter_config.map((filter, index:number) => (
                                                <Tab 
                                                    key = {`FILTER-KEY-00${index}`} 
                                                    value={filter.name} 
                                                    label={`${filter.title} (${filter.name === "all" ? affairs.length : affairs.filter(inv => filter.name === inv.type).length})`}
                                                />
                                            ))
                                        }
                                    </Tabs>
                                </Box>
                                <MuiSearchBox
                                    search_string={manager.search_string}
                                    setSearchString={(search_string) => setManager(state => {return {...state, search_string}})}
                                />
                                </div>

                            </div>
                            <div className="affairs__listing--content">
                                <DataGridPro
                                    loading={manager.load_data_grid}
                                    rows={filter_custom(data.rows)}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                    rowHeight={40}
                                    checkboxSelection
                                    //disableColumnMenu={true} 
                                    //disableColumnResize={true}
                                    hideFooter={true}
                                    editMode="row"
                                    onRowSelectionModelChange={handleSelectionChange}
                                    getRowId={(row:any) => row._id}
                                    onRowDoubleClick={(row) => {
                                        
                                        setManager(state => {return{...state, mode : "detail", selected_data : row.row._id}})
                                    }}
                                    columns={data.columns.map(column => {
                                        return {
                                            ...column,
                                            editable: false,
                                            flex: 1, // Donne une flexibilité égale à chaque colonne
                                        }
                                    }) as any}
                                    sx={{
                                        ...data_grid_x_sx, 
                                        border : "none!important",
                                        "& .MuiFormControl-root, .MuiInputBase-root, .MuiInputBase-root input" : {
                                            height : '100%',
                                            justifyContent : 'center'
                                        }, 
                                        "& .MuiInputBase-input, .MuiFormControl-root" : {
                                            padding : 0, 
                                        },
                                        "& .MuiInputBase-input" : {
                                            paddingLeft : "10px", 
                                            width : "calc(100% - 10px)", 
                                        },
                                        "& .MuiDataGrid-cell" : {
                                            backgroundColor : "transparent!important", 
                                        }, 
                                        "& .MuiDataGrid-cell--textLeft *" : {
                                            fontSize : "0.875rem"
                                        }, 
                                        ".MuiDataGrid-cell:focus" : {
                                            outline : "none!important"
                                        }
                                    }} 
                                />
                                {
                                    (filter_custom(data.rows).length === 0 && manager.initialize) ? 
                                        <NoDataGrid />
                                    : 
                                        null
                                }
                            </div>

                        </div>
                }
            </BaseContainer>
        </BaseApp>
    )
}