
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react' ; 
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { account_user } from '../../../store/account/selector.account';
import { ServerProps, server_host } from '../../../config/server.config';
import { addRequestStatus, setLoaderStatus} from '../../../store/global/actions.global';
import BasePopup from '../../../tools/components/general/BasePopup';
import { Icons, ObjectUtils} from 'tc-minibox';
import MainButton from '../../../tools/components/general/MainButton';
import 'tippy.js/dist/tippy.css';
import { paymentStatuses } from '../payment.utils';
import { green_style_type_payments, type_payments } from '../../suppliers/utils.suppliers';
import Tippy from '@tippyjs/react';
import FilterBox, { FilterManager, initial_state_manager } from './FilterBox';
import ChangeStatusPopup from './grids/ChangeStatusPopup';
import { credit_note_style } from '../../invoices/suppliers/suppliersinv.utils';
import moment from 'moment';
import { DataGridPro, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { frFR } from '@mui/x-data-grid/locales';
import { data_grid_x_sx } from '../../../config/mui_style.config';
import { Button, Menu, MenuItem, Tooltip, tooltipClasses } from '@mui/material';
import { ChangeCircleRounded, IosShareRounded } from '@mui/icons-material';
import { button_sx } from '../../../config/mui_style.config';
import MuiSearchBox from '../../../templates/MuiSearchBox';
import { PaymentsManager } from '../Payments';
import NoDataGrid from '../../../templates/NoDataGrid';


export interface ListingProps extends ServerProps {
    data : any[],
    setData : React.SetStateAction<any>, 
    handleAdd : () => void, 
    manager : PaymentsManager, 
    setManager : React.SetStateAction<any>, 
    load_data_grid : boolean
}

export interface ListingInvManager {
    initialization : boolean, 
    preview : {
        status : boolean, 
        content : any
    }, 
    comment : {
        status : boolean, 
        mode : null | "refused" | "validated" | "sent", 
        selected_data : string[]
    }, 
    refused : {
        status : boolean, 
        content : any
    }, 
    change_status_popup : {
        status : boolean, 
        selected_data : string[]
    }, 
    status : string, 
    message : string | null, 
    filter : string, 
    selected_data : any, 
    search_string : string
}

export default function ListingInv(props:ListingProps) {
    //* GLOBAL
    const dispatch = useDispatch()

    // * STATES
    const user_account = useSelector(account_user)
    const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null)
    const [manager, setManager] = useState<ListingInvManager>({
        initialization : false, 
        comment : {
            status : false, 
            mode : null, 
            selected_data : []
        }, 
        preview : {
            status : false,
            content : null
        }, 
        refused : {
            status : false, 
            content : {}
        }, 
        change_status_popup : {
            status : false, 
            selected_data : []
        }, 
        status : "pending", 
        message : null, 
        filter : "all", 
        selected_data : null, 
        search_string : ""
    })
    const [selectedRows, setSelectedRows] = useState<string[]>([])

    const [filterBoxManager, setFilterBoxManager] = useState<FilterManager>(initial_state_manager)
    
    // * VARIABLES
    const tbody:any[] = props.data.map((inv:any) => {
        const last_comment_log = inv.comments_log.length === 0 ? null : inv.comments_log.sort((a:any, b:any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]
        
        const totalPaidAmount = inv.payments_log.reduce((total:any, payment:any) => total + parseFloat(payment.amount), 0);
        const restToPay = (parseFloat(inv.total_ttc) - totalPaidAmount).toFixed(2);
        
        return {
            ...inv, 
            tc_affairs_numbers :  inv.id_affairs.map((aff:any) => aff.id.affair_number).join(inv.id_affairs.length === 1 ? '' : ' ; '), 
            tc_supplier : inv.id_supplier.name, 
            tc_due_date : inv.dates.due_date,
            tc_receipt_date : inv.dates.receipt_date,
            tc_inv_date : inv.dates.inv_date,
            tc_total_ht : `${(inv.total_ttc - inv.total_tva).toFixed(2)} €`,
            tc_total_tva : `${inv.total_tva.toFixed(2)} €`,
            tc_total_ttc : `${inv.total_ttc.toFixed(2)} €`,
            tc_payments_mode : last_comment_log === null ? `${type_payments.find((tp:any) => tp.name === inv.id_supplier.payment_choice)?.text} (Par défaut)` : `${last_comment_log.content.length > 0 ? last_comment_log.content : "Aucun commentaire"}`, 
            tc_designations : "", 
            tc_payment_status : parseFloat(restToPay) === 0 ? "Payée" : inv.type === "credit-note" ? "Avoir" : `${restToPay}€`,

        }
    })


    // ? Selected rows by original data (props.data)
    var get_info_selected_data = props.data.filter((dt:any) => selectedRows.includes(dt._id))
    get_info_selected_data = get_info_selected_data.map((st:any) => {
        return {
            ...st, 
            last_status : st.status_log.sort((a:any, b:any) => b.date - a.date)[0]
        }
    })

    // * FUNCTIONS
    const handleChangeStatus = (message : string) => {
        const formContainer = {
            status : manager.comment.mode, 
            user : user_account.infos._id, 
            date : new Date(), 
            comment : message, 
            selected_data : manager.comment.selected_data
        }
        
        return props.server.post('/invoicing/manage-inv-status', formContainer)
        .then(res => {
            dispatch(addRequestStatus(res.data))
            setManager(state => {
                return {
                ...state, 
                comment : {status : false, mode : null, selected_data : []}
            }})

            props.setManager((state:any) => {return {...state, initialize : false}})
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
        })
    }


    const handleExport = async (selected_data:any, mode:string, attachements:boolean) => {
        return props.server.post('/payments/export', {
            selected_data : selected_data,
            mode : mode, 
            attachements : attachements
        })
        .then(res => {
            //dispatch(addRequestStatus(res.data))
            const link = document.createElement('a');
            link.href = server_host(`${res.data.data}?token=${user_account.infos.token_doc}`);
            link.download = attachements ? 'Suivi facturation.zip' : "Suivi facturation.xlsx"; // Nom du fichier à télécharger
            link.target = '_blank'; // O
              // Ajouter le lien au DOM et le déclencher
                document.body.appendChild(link);
                link.click();

                // Supprimer le lien du DOM une fois le téléchargement terminé
                document.body.removeChild(link);

            dispatch(addRequestStatus(res.data))
            
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })
        /*
        try {
            const res = await props.server.post('/invoicing/export', { selected_data: selected_inv, date: current_date, id_user: user_account.infos.id_user });
            dispatch(addRequestStatus(res.data));

            const link = document.createElement('a');
            link.href = server_host(res.data.data);
            link.download = 'Factures.zip'; // Nom du fichier à télécharger
            link.target = '_blank'; // O


            // Ajouter le lien au DOM et le déclencher
            document.body.appendChild(link);
            link.click();

            // Supprimer le lien du DOM une fois le téléchargement terminé
            document.body.removeChild(link);

            dispatch(setLoaderStatus({ status: false }));
        } catch (err:any) {
            dispatch(setLoaderStatus({ status: false }));
            dispatch(addRequestStatus(err.response.data));
        }*/
    }

    const filter_custom = (rows:any[]) => {
        var cleaned_data:any = rows.map((row) => {
            return {
                ...row,
                search_string: `${row.tc_affairs_numbers} ${row.doc_number} ${row.inv_number} ${row.tc_supplier} ${row.tc_due_date} ${moment(row.tc_inv_date).format("DD/MM/YYYY")} ${row.tc_total_ht} ${row.tc_total_tva} ${row.tc_total_ttc} ${row.tc_payments_mode} ${row.tc_designations}`.replaceAll(" ", ""),
            };
        })

        const search_string = manager.search_string.toLowerCase().replaceAll(" ", "");  // Assumé depuis votre gestionnaire de recherche
        const matchData = cleaned_data.filter((da:any) => {
            const match = da.search_string.match(new RegExp(search_string, "i"));  // Utilise RegExp pour la correspondance insensible à la casse
            return match != null;
        }).sort((a:any, b:any) => {
            const matchA = a.search_string.match(new RegExp(search_string, "i"));
            const matchB = b.search_string.match(new RegExp(search_string, "i"));
    
            // Calculer le nombre de correspondances (bien que match retourne juste la première correspondance, donc ce sera toujours 1 ou 0 dans ce cas)
            const countA = matchA ? matchA.length : 0;
            const countB = matchB ? matchB.length : 0;
    
            // Trier en fonction du nombre de correspondances (du plus élevé au plus bas)
            if (countA > countB) {
                return -1; // a avant b
            } else if (countA < countB) {
                return 1; // b avant a
            } else {
                return 0; // aucun changement d'ordre
            }
        });
        
        var result = rows.filter((da:any) => matchData.some((md:any) => md._id === da._id))
        if(filterBoxManager.analytic_code !== "") {
            
            result = result.filter((inv:any) => props.data.find((dt:any) => dt._id === inv._id).id_affairs.map(((aff:any) => `${aff.id.analytic_code}`.trim())).includes(filterBoxManager.analytic_code))
        }

        if(filterBoxManager.payment_mode !== null) {
            result = result.filter((inv:any) => {
                const data_inv = props.data.find((dt:any) => dt._id === inv._id)
                const totalPaidAmount = data_inv.payments_log.reduce((total:number, payment:any) => total + parseFloat(payment.amount), 0);
                const restToPay = (parseFloat(data_inv.total_ttc) - totalPaidAmount).toFixed(2);

                return filterBoxManager.payment_mode === "paid" ? parseFloat(restToPay) === 0 : filterBoxManager.payment_mode === "credit-note" ? data_inv.type === "credit-note" : parseFloat(restToPay) > 0
            })
        }

        if(filterBoxManager.supplier !== null) {
            result = result.filter((inv:any) => props.data.find((dt:any) => dt._id === inv._id).id_supplier._id === filterBoxManager.supplier)
        }

        if(filterBoxManager.status !== "all") {
            if(filterBoxManager.status === "no-status") {
                result = result.filter((inv:any) => props.data.find((dt:any) => dt._id === inv._id).comments_log.length === 0)
            } else {
                result = result.filter((inv:any) => {
                    const last_status = props.data.find((dt:any) => dt._id === inv._id).comments_log.sort((a:any, b:any) => b.date - a.date)[0]

                    if(last_status === undefined) {
                        return false
                    }

                    return last_status.name === filterBoxManager.status
                })
            }
        }

        if( filterBoxManager.inv_date !== null) {
            result = result.filter((inv:any) => {
                const inv_date = new Date(props.data.find((dt:any) => dt._id === inv._id).dates.inv_date)
                return inv_date.getTime() === filterBoxManager.inv_date?.getTime()
            })
        }

        if( filterBoxManager.receipt_date !== null) {
            result = result.filter((inv:any) => {
                const receipt_date = new Date(props.data.find((dt:any) => dt._id === inv._id).dates.receipt_date)
                return receipt_date.getTime() === filterBoxManager.receipt_date?.getTime()
            })
        }

        if( filterBoxManager.due_date_start !== null) {
            result = result.filter((inv:any) => {
                const due_date = new Date(props.data.find((dt:any) => dt._id === inv._id).dates.due_date)
                due_date.setHours(0, 0, 0, 0)
                const selected_due_date_end = filterBoxManager.due_date_end === null ? new Date() : filterBoxManager.due_date_end
                return due_date.getTime() >= (filterBoxManager.due_date_start as any).getTime() && due_date.getTime() <= selected_due_date_end.getTime()
                
            })
        }


        return result
        
    }

    const handleSelectionChange = (newSelection: GridRowSelectionModel) => {
        setSelectedRows(newSelection as any);
    };


    function arrayToTSV(data:any, keys:any) {
        // Utilise les clés spécifiées pour les en-têtes
        const tsvRows = [];
        
        // Convertit chaque objet en une ligne TSV en utilisant uniquement les clés spécifiées
        for (const row of data) {
            const values = keys.map((key:any) => {
                const val = row[key];
                // Échappe les valeurs contenant des tabulations, des guillemets ou des sauts de ligne
                const escapedVal = ('' + val).replace(/"/g, '""').replace(/\t/g, ' ').replace(/\n/g, ' ');
                return `${escapedVal}`;
            });
            tsvRows.push(values.join('\t'));
        }
        
        // Retourne toutes les lignes TSV comme une seule chaîne de texte
        return tsvRows.join('\n');
    }
    
    // Fonction pour copier le texte dans le presse-papiers
    function copyToClipboard(text:any) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                dispatch(addRequestStatus({status : true, message : "Texte copié dans le presse-papiers"}))
            }).catch(err => {
                dispatch(addRequestStatus({status : false, message : "Impossible de copier le texte dans le presse-papiers"}))
            });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.style.position = 'fixed';  // Évite que le textarea soit visible
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                console.log('Fallback: Text successfully copied to clipboard');
            } catch (err) {
                console.error('Fallback: Error in copying text: ', err);
            }
            document.body.removeChild(textArea);
        }
    }

    const element_sizes = {
        status : 190, 
        payment : 130, 
        doc_number : 100,
        inv_number : 150, 
        supplier : 250, 
        inv_date : 100,
        reception_date : 100,
        payment_date : 110,
        total_ht : 90,
        total_tva : 90,
        total_ttc : 90,
        payment_mode : 300,
        designation : 300,
        codes : 100
    }

    const data = {
        columns : [
        { 
            field: 'status', 
            headerName: 'Statut', 
            minWidth: element_sizes.status, 
            resizable: true, 
            renderCell: (params: any) => {
                const tr = params.row;
                var lastCommentStatus = tr.comments_log.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())[0];
                lastCommentStatus = paymentStatuses.find(status => status.name === lastCommentStatus?.name);
    
                return (
                    <div 
                        style={{
                            backgroundColor: lastCommentStatus?.color, 
                            color: lastCommentStatus?.textColor
                        }}
                        className="comment-status"
                    >
                        <p 
                            style={{
                                color: lastCommentStatus?.textColor
                            }}
                            className="text"
                        >
                            {lastCommentStatus ? lastCommentStatus.title : "Aucun statut"}
                        </p>
                    </div>
                );
            }
        },
        { 
            field: 'tc_payment_status', 
            headerName: 'À payer', 
            minWidth: element_sizes.payment, 
            renderCell: (params: any) => {
                const tr = params.row;
                const totalPaidAmount = tr.payments_log.reduce((total: any, payment: any) => total + parseFloat(payment.amount), 0);
                const restToPay = (parseFloat(tr.total_ttc) - totalPaidAmount).toFixed(2);
    
                return (
                    <div className={`tc-payment-status tc-payment-status${parseFloat(restToPay) === 0 ? "--paid" : tr.type === "credit-note" ? "--credit-note" : "--to_pay" } `}>
                        <p>{tr.tc_payment_status}</p>
                    </div>
                );
            }
        },
        {
            field: "tc_affairs_numbers",
            headerName: "Codes",
            minWidth: element_sizes.codes,
            flex : 1,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.tc_affairs_numbers}
                    </p>
                );
            },
            align: "center",
        },
        { 
            field: 'doc_number', 
            headerName: 'N° pièce comptable', 
            minWidth: element_sizes.doc_number , 
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    !green_style_type_payments.includes(tr.id_supplier.payment_choice) ? 
                        <p className={`doc_number`}>
                            {tr.doc_number}
                        </p>
                    :
                        <Tippy
                        content={(
                            <p className={`doc_number--note`}>
                                Mode de paiement auto
                            </p>
                        )}
                        interactive={false}
                        trigger="mouseenter focus"
                        placement="bottom"
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'flip',
                                    options: {
                                        fallbackPlacements: ['top', 'right', 'left'],
                                    },
                                },
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 10],
                                    },
                                },
                            ],
                        }}
                        >
                            <p className={`doc_number ${green_style_type_payments.includes(tr.id_supplier.payment_choice) ? "doc_number--green" : ""}`} style={{ ...tr.type === "credit-note" ? credit_note_style : {} }}>
                                {tr.doc_number}
                            </p>
                        </Tippy>
                );
            },
        },
        {
            field: "inv_number",
            headerName: "N° de facture",
            minWidth: element_sizes.inv_number,
            flex : 1,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.inv_number}
                    </p>
                );
            },
            align: "left",
        },
        {
            field: "tc_supplier",
            headerName: "Fournisseur",
            minWidth: element_sizes.supplier,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.tc_supplier}
                    </p>
                );
            },
            align: "left",
        },
        {
            field: "tc_inv_date",
            headerName: "Date de la facture",
            minWidth: element_sizes.inv_date,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {moment(tr.tc_inv_date).format('DD/MM/YYYY')}
                    </p>
                );
            },
            align: "center",
        },
        {
            field: "tc_receipt_date",
            headerName: "Date de réception",
            minWidth: element_sizes.reception_date,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {moment(tr.tc_receipt_date).format('DD/MM/YYYY')}
                    </p>
                );
            },
            align: "center",
        },
        {
            field: "tc_due_date",
            headerName: "Date de règlement",
            minWidth: element_sizes.payment_date,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.is_invoice_on_receipt ? "À réception de la facture" : moment(tr.tc_due_date).format('DD/MM/YYYY')}
                    </p>
                );
            },
            align: "center",
        },
        {
            field: "tc_total_ht",
            headerName: "H.T",
            minWidth: element_sizes.total_ht,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.tc_total_ht}
                    </p>
                );
            },
            align: "right",
        },
        {
            field: "tc_total_tva",
            headerName: "TVA",
            minWidth: element_sizes.total_tva,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.tc_total_tva}
                    </p>
                );
            },
            align: "right",
        },
        {
            field: "tc_total_ttc",
            headerName: "TTC",
            minWidth: element_sizes.total_ttc,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <p style={tr.type === "credit-note" ? credit_note_style : {}}>
                        {tr.tc_total_ttc}
                    </p>
                );
            },
            align: "right",
        },
        {
            field: "comment",
            headerName: "Désignation",
            minWidth: element_sizes.designation,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <div className='tc_comment'>
                        <Tippy
                            content={<div>{tr.comment}</div>}
                            interactive={true}
                            trigger="click"
                            placement="bottom"
                            popperOptions={{
                                modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                            fallbackPlacements: ['top', 'right', 'left'],
                                        },
                                    },
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 10],
                                        },
                                    },
                                ],
                            }}
                        >
                            <p style={tr.type === "credit-note" ? credit_note_style : {}}>{tr.comment}</p>
                        </Tippy>
                    </div>
                );
            },
            align: "left",
        },
        {
            field: "tc_payments_mode",
            headerName: "Mode de règlement",
            minWidth: element_sizes.payment_mode,
            renderCell: (params:any) => {
                const tr = params.row;
                return (
                    <div className='tc_payments_mode'>
                        <Tippy
                            content={<div>{tr.tc_payments_mode}</div>}
                            interactive={true}
                            trigger="click"
                            placement="bottom"
                            popperOptions={{
                                modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                            fallbackPlacements: ['top', 'right', 'left'],
                                        },
                                    },
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, 10],
                                        },
                                    },
                                ],
                            }}
                        >
                            <p style={tr.type === "credit-note" ? credit_note_style : {}}>{tr.tc_payments_mode}</p>
                        </Tippy>
                    </div>
                );
            },
            align: "left",
        },
        ], 
        rows : tbody
    }

    return (
        <React.Fragment>
            {
                manager.change_status_popup.status ? 
                    <ChangeStatusPopup 
                        server={props.server}
                        handleClose = {() => setManager(state => {return {...state, change_status_popup : {status : false, selected_data : []}}})}
                        rerender = {() => props.setManager((state:any) => {return {...state, initialize : false}})}
                        selected_data = {manager.change_status_popup.selected_data}
                        data = {props.data}
                        setData = {props.setData}
                    />

                : null 
            }
            {
                manager.comment.status ? 
                    <PopupStatusMessage
                        handleClose = {() => {
                            setManager(state => {return {...state, comment : {status : false, mode : null, selected_data : [], content : ""}}})
                        }}
                        handleSubmit = {(message:any) => handleChangeStatus(message)}
                    />
                : null
            }
            {
                manager.preview.status ?
                    <PopupInvoicePreview
                        handleClose = {() => setManager(state => {return {...state, preview : {status : false, content : null}}})}
                        url = {manager.preview.content}
                    />
                : 
                    null
            }
            <div className = "invoice-detail">
                <div className='invoice-detail--header'>
                    <div className='buttons'>
                        {
                            selectedRows.length > 0 ?
                                <Button
                                variant="contained"
                                color="primary"
                                onClick = {() => setManager(state => {return {...state, change_status_popup : {...state.change_status_popup, status : true, selected_data : get_info_selected_data.map((sd:any) => sd._id)}}})}
                                startIcon={<ChangeCircleRounded />}
                                sx={{
                                    ...button_sx, 
                                    backgroundColor: '#2196f3', // Couleur de fond normale
                                    '&:hover': {
                                    backgroundColor: '#0d47a1' // Couleur de fond au survol
                                    },
                                }}
                                >
                                    Changer le statut
                                </Button>
                            : 
                                null
                        }

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => setExportAnchor(event.currentTarget)}
                            startIcon={<IosShareRounded />}
                            sx = {{
                                ...button_sx, 
                                marginLeft : "5px",
                                backgroundColor : "rgb(126, 125, 125)", 
                                "&:hover" : {
                                    backgroundColor : "rgb(91 90 90)", 
                                }
                            }}
                            className='buttons__export'
                        >
                            Exporter
                        </Button>                
                        <Menu
                            anchorEl={exportAnchor}
                            open={Boolean(exportAnchor)}
                            onClose={() => setExportAnchor(null)}
                            className='buttons__export--list' 
                        >
                                <MenuItem
                                    onClick={() => copyToClipboard(arrayToTSV(get_info_selected_data, ["inv_number", "doc_number"]))}
                                >
                                    Copier pour rapprochement
                                </MenuItem>
                            {
                                selectedRows.length > 0 ?
                                    <React.Fragment>
                                        <MenuItem
                                            onClick = {() => {
                                                handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", false)
                                                setExportAnchor(null)
                                            }}
                                        >
                                            Exporter la sélection
                                        </MenuItem>
                                        <MenuItem
                                            onClick = {() => {
                                                handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", true)
                                                setExportAnchor(null)
                                            }}
                                        >
                                            Exporter la sélection + pièces
                                        </MenuItem>
                                    </React.Fragment>
                                : 
                                    null

                            }
                            <MenuItem
                                onClick={() => {
                                    handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", false)
                                    setExportAnchor(null)
                                }}
                            >Exporter tout</MenuItem>
                                <MenuItem 
                                onClick={() => {
                                    handleExport(get_info_selected_data.map((sd:any) => sd._id), "selected", true)
                                    setExportAnchor(null)
                                }}
                            >Exporter tout + pièces</MenuItem>
                        </Menu>
                    </div>
                    <div className='tippy-filter-box'>
                        <Tippy
                            content={(
                                <FilterBox 
                                    server = {props.server}
                                    manager = {filterBoxManager}
                                    setManager = {setFilterBoxManager}
                                />
                            )}
                            zIndex={1000}
                            interactive={true}
                            trigger="click"
                            placement="bottom"
                            popperOptions={{
                                modifiers: [
                                {
                                    name: 'flip',
                                    options: {
                                    fallbackPlacements: ['top', 'right', 'left'], // Emplacements de repli si 'bottom' n'est pas disponible
                                    },
                                },
                                {
                                    name: 'offset',
                                    options: {
                                    offset: [0, 5], // Décale le tooltip de 10px vers le bas
                                    },
                                },
                                ],
                            }}
                        >
                            <motion.div className={`filter-box__button ${Object.values(ObjectUtils.getObjectDifference(initial_state_manager, filterBoxManager)).length > 0 ? "filter-box__button--active" : null } `}>
                                {
                                    //
                                }
                                {
                                    Object.values(ObjectUtils.getObjectDifference(initial_state_manager, filterBoxManager)).length > 0 ? 
                                        <div className='filter-box__button--length'>
                                        {`${Object.values(ObjectUtils.getObjectDifference(initial_state_manager, filterBoxManager)).length}`}
                                        </div>
                                    : null
                                }

                                <Icons name = "filter" mode = "duotone" color = "#007aff" />
                            </motion.div>
                        </Tippy>
                    </div>
                    <MuiSearchBox
                        search_string={manager.search_string}
                        setSearchString={(search_string) => setManager(state => {return {...state, search_string}})}
                    />

                </div>
                <div className='invoice-detail--content'>
                <DataGridPro
                    loading={props.load_data_grid}
                    rows={filter_custom(data.rows)}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    rowHeight={40}
                    checkboxSelection
                    //disableColumnMenu={true} 
                    //disableColumnResize={true}
                    hideFooter={true}
                    editMode="row"
                    onRowSelectionModelChange={handleSelectionChange}
                    disableColumnReorder={false}
                    getRowId={(row:any) => row._id}
                    onRowDoubleClick={(row) => {
                        const selected_data = props.data.find((d:any) => d._id === row.row._id)
                        console.log(selected_data)
                        props.setManager((state:any) => {return {...state, selected_data : selected_data}})
                    }}
                    getRowClassName={(params) => {
                        // Exemple de logique pour ajouter une classe basée sur une condition
        
                        return params.row.type === "credit-note" ? 'MuiDataGrid-row--credit-note' : '';
                    }}
                    columns={data.columns.map(column => {
                        return {
                            ...column,
                            editable: false,
                            flex: 1, // Donne une flexibilité égale à chaque colonne
                        }
                    }) as any}
                    

                    sx={{
                        ...data_grid_x_sx, 
                        border : "none!important",
                        "& .MuiFormControl-root, .MuiInputBase-root, .MuiInputBase-root input" : {
                            height : '100%',
                            justifyContent : 'center'
                        }, 
                        "& .MuiInputBase-input, .MuiFormControl-root" : {
                            padding : 0, 
                        },
                        "& .MuiInputBase-input" : {
                            paddingLeft : "10px", 
                            width : "calc(100% - 10px)", 
                        },
                        "& .MuiDataGrid-cell" : {
                            backgroundColor : "transparent!important", 
                        }, 
                        "& .MuiDataGrid-cell--textLeft *" : {
                            fontSize : "0.875rem"
                        }, 
                        ".MuiDataGrid-cell:focus" : {
                            outline : "none!important"
                        }
                    }} 
                />
                    {
                        filter_custom(data.rows).length === 0 && props.manager.initialize ? 
                            <NoDataGrid />
                        : 
                            null
                    }
                </div>
                <div className='invoice-detail--footer'>

                        <div id='selected-lines'>
                            <Tooltip
                                title="Lignes sélectionnées"
               
                                slotProps={{
                                    popper: {
                                      sx: {
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginTop: '0px',
                                          },
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginBottom: '0px',
                                          },
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginLeft: '0px',
                                          },
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginRight: '0px',
                                          },
                                      },
                                    },
                                  }}
                            >
                            <p>
                                <span>{selectedRows.length}</span>
                                /
                                <span>{filter_custom(tbody).length}</span>
                            </p>
                            </Tooltip>
                        </div>


                    <div id='totals'>
                            <Tooltip
                                title="Totaux de la sélection"
                                slotProps={{
                                    popper: {
                                      sx: {
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginTop: '0px',
                                          },
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginBottom: '0px',
                                          },
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginLeft: '0px',
                                          },
                                        [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                          {
                                            marginRight: '0px',
                                          },
                                      },
                                    },
                                  }}
                            >
                            <div id="totals--container">
                                <div>
                                    <h2>HT</h2>
                                    <p>
                                        {filter_custom(tbody).filter((inv:any) => selectedRows.includes(inv._id)).reduce((total:number, inv:any) => total + parseFloat(inv.tc_total_ht), 0).toFixed(2)}€
                                    </p>
                                </div>
                                <div>
                                    <h2>TVA</h2>
                                    <p>
                                        {filter_custom(tbody).filter((inv:any) => selectedRows.includes(inv._id)).reduce((total:number, inv:any) => total + parseFloat(inv.tc_total_tva), 0).toFixed(2)}€
                                    </p>
                                </div>
                                <div>
                                    <h2>TTC</h2>
                                    <p>
                                        {filter_custom(tbody).filter((inv:any) => selectedRows.includes(inv._id)).reduce((total:number, inv:any) => total + parseFloat(inv.tc_total_ttc), 0).toFixed(2)}€
                                    </p>
                                </div>
                            </div>
                            </Tooltip>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


function PopupStatusMessage(props:any) {
    const [message, setMessage] = useState("")

    return (
        <BasePopup
            nameClass = "popup-message-refused"
            handleClose = {props.handleClose}
        >
            <p>Souhaitez-vous ajouter un commentaire ?</p>
            <textarea
                value = {message}
                onChange = {(e) => setMessage(e.target.value)}
                placeholder='Tapez ici...'
            />
            <MainButton
                handleClick = {() => props.handleSubmit(message)}
            >
                Valider
            </MainButton>
        
        </BasePopup>
    )
}

function PopupInvoicePreview(props:any) {
    return (
        <BasePopup
            nameClass = "popup-invoice-preview"
            handleClose = {props.handleClose}
        >
            <iframe src={`${props.url}`} title="Web Page Viewer" className="iframe-container"/>
        </BasePopup>
    )
}