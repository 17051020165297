import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'; 
import { useSelector } from 'react-redux';
import { container_homepage, illustration_homepage, intro_homepage } from './animations/homepage.animations';
import {ReactComponent as HomepageIll} from '../../assets/svg/illustrations/homepage_illustration.svg'; 
import { account_user } from '../../store/account/selector.account';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { useEffect } from 'react';

export default function Homepage() {
    const account = useSelector(account_user)

    useEffect(() => {
        document.title = "Accueil - MyOGEC";
    }, []);

    return (
        <BaseContainer nameClass = "homepage">
            {/* /// INTRODUCTION /// */}
            <motion.div 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants={intro_homepage}
                className='intro'
            >
                <h1>Bienvenue {account.infos.firstname} {account.infos.lastname},</h1>
                <p>Ravi de vous revoir !</p>
            </motion.div>

            {/* /// CONTAINER (BUTTONS + SEARCH) /// */}
            <motion.div 
               exit = "exit"
               animate = "animate"
               initial = "initial"
               variants={container_homepage}
                className='container'
            >
                <h2>Que souhaitez-vous faire ?</h2>
                <div>
                    <motion.div

                    >
                        <Link to = "/new">Créer une nouvelle affaire</Link>
                        </motion.div>
                        <motion.div>
                            <Link to = "/affairs">Listing des affaires</Link>
                        </motion.div>
                </div>
            </motion.div>

            {/* /// ILLUSTRATION /// */}
            <motion.div 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                variants={illustration_homepage}
                className='illustration'
            >
                <HomepageIll />
            </motion.div>
        </BaseContainer>

    )
}

/*                    <motion.div>
                        <Icons name = "magnifying-glass" {...icons_config} />
                        <input type = "text" placeholder='Rechercher une affaire' />
                    </motion.div>

*/