import {useEffect, useRef, useState} from 'react' ; 
import {motion} from 'framer-motion';
import ReCAPTCHA from 'react-google-recaptcha';
import { ServerProps } from "../../config/server.config";
import ContainerLoad from '../../tools/components/general/ContainerLoad';
import {ReactComponent as Logo} from '../../assets/svg/logo.svg'
import { useDispatch } from 'react-redux';
import { addRequestStatus } from '../../store/global/actions.global';
import { set_authentification } from '../../store/account/actions.account';
import MainButton from '../../tools/components/general/MainButton';
import { Link } from 'react-router-dom';
import auth_illustration from '../../assets/images/auth_illustration.png'
import { button_sx, textField_sx } from '../../config/mui_style.config';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type SignInManager = {
    mode : "form" | "load"
}

type SignInForm = {
    mail : string,
    pwd : string,
    recaptcha : string | null
}

export default function SignIn(props:ServerProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const emailRef = useRef<HTMLInputElement>(null);
    const pwdRef = useRef<HTMLInputElement>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);    

    // * STATES
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [manager, setManager] = useState<SignInManager>({mode :  "form"})
    const [form, setForm] = useState<SignInForm>({mail : "", pwd : "", recaptcha : null})

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => { setForm(state => { return { ...state, [e.target.name]: e.target.value }; }) };

    // * FUNCTIONS
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    //Submit login
    const handleSubmit = () => {
        // # IF # 3 attempts : Need to complete a recaptcha
        
        if (parseInt(localStorage.getItem('attempt') || '') === 3 && form.recaptcha === null) {
            let response = {
                status : false, 
                message : "Veuillez compléter le ReCaptcha."
            }
            return dispatch(addRequestStatus(response))
        }

        // # IF # Mail pattern verification : Raise an error if not
        if(!form.mail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/)) {
            let response = {
                status : false, 
                message : "L'adresse mail est invalide."
            }
            return dispatch(addRequestStatus(response))
        }

        // # IF # Password length verification
        if(form.pwd.length < 5 ) {
            let response = {
                status : false, 
                message : "Le mot de passe est invalide"
            }
            return dispatch(addRequestStatus(response))
        }

        setManager(state => {return{...state, mode : "load"}})

        return props.server.post('auth/sign-in', form)
        .then(res => {
            localStorage.clear()
            // ! REFAIRE LE BACKEND DE ÇA
            dispatch(set_authentification({infos : res.data.data, status : true, token : res.data.data.token_session}))
            setManager(state => {return{...state, mode : "form"}})
            return dispatch(addRequestStatus(res.data))
        })
        .catch(err => {
            /*if(!localStorage.getItem('attempt')) {
                localStorage.setItem('attempt', "1")
            } else {
                const attempt = parseInt(localStorage.getItem('attempt') || '')
                if(isNaN(attempt) || attempt > 3 || attempt < 1) {
                    localStorage.setItem('attempt', "3")
                } else if (attempt < 3 && attempt > 0) {
                    localStorage.setItem('attempt', `${attempt + 1}`)
                } else {
                    //REVOIR LE RESET 
                    recaptchaRef.current?.reset();
                }

            }*/
            
            setManager(state => {return{...state, mode : "form"}})
            dispatch(addRequestStatus(err.code !== "ERR_NETWORK" ? err.response.data : {status : false, message : "Erreur réseau, veuillez vérifier votre connexion internet ou le cas échéant, contacter l'administrateur du site."}))
        })

    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter") {
            e.preventDefault()
            handleSubmit()
        }
    }

    // * USE EFFECTS
    useEffect(() => {
        if (emailRef.current && !emailRef.current.value) {
          emailRef.current.focus();
        } else if (pwdRef.current) {
          pwdRef.current.focus();
        }
    }, []);


    return (
        <div className = "sign-in">
        {/* FORM */}
        <form
            //exit = {{x : -1000}}
            //animate = {{x : 0, transition : {delay : 0.3, type : "linear"}}}
            //initial = {{x : -1000}}
            className='form'
        >
            <ContainerLoad load = {manager.mode === "load"}>
                <div className='logo'><Logo /></div>
                <h1>Connexion</h1>
                <div className='content'>
                    <div>
                        <TextField
                            ref = {emailRef}
                            id="outlined-email" 
                            name = "mail"
                            autoComplete='off'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChangeInput}
                            onKeyDown={handleKeyDown}
                            value={form.mail}
                            label="E-mail" 
                            variant="outlined"
                            sx={textField_sx}
                            placeholder = "xxxxx@example.com"
                            inputProps={{
                                
                            }}
                        />
                    </div>
                <div id="password">
                    <TextField
                        id="outlined-password"
                        name = "pwd"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChangeInput}
                        onKeyDown={handleKeyDown}
                        value={form.pwd}
                        label="Mot de passe" 
                        variant="outlined"
                        sx={textField_sx}
                        placeholder = "xxxxx"
                        inputProps={{
                        }}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                                sx={{height : "10px", width : "auto", color: "#116633"}}
                                >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                    />
                </div>
                {
                    localStorage.getItem('attempt') === "3" ? 
                        <ReCAPTCHA
                            sitekey='6LeRzP8pAAAAAPzJbQBSfBWfrh5lU7tA9YzcLVt5'
                            ref = {recaptchaRef}
                            id = {'Recaptcha'}
                            onChange={(value) => setForm(state => {return{...state, recaptcha : value}})}
                        />
                    : 
                        null
                }

                <Button variant="contained" sx = {button_sx} onClick={handleSubmit}>Valider</Button>
                <Link
                    to = "/forgot-pwd"
                    className='button-forgot-pwd'
                >
                    Mot de passe oublié ? Cliquez ici
                </Link>
                </div>
            </ContainerLoad>
        </form>

        {/* ILLUSTRATION */}
        <motion.div 
            className='illustration'
            exit = {{y : 100, opacity: 0}}
            animate = {{y : 0, opacity: 1,  transition : {delay : 0, duration : 0.5, type : "linear"}}}
            initial = {{y : 100, opacity : 0}}
        >
            <img src={auth_illustration} alt="auth_illustration" />
        </motion.div>
        </div>
    )
}