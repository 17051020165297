import React, {useEffect, useState} from 'react' ; 
import { InvoiceProcessingEvents, InvoiceProcessingManager } from '../ProcessingDesposit';
import { Icons, StringUtils } from 'tc-minibox';
import { motion } from 'framer-motion';
import moment from 'moment';

export interface ListingProcessingProps {
    data : any[]
    data_object : {
        to_revise : Array<any> ;
        ready : Array<any> ;
    }, 
    events : InvoiceProcessingEvents ; 
    manager : InvoiceProcessingManager, 
    setEvents : any ;
    handleSelect : any ;
    handleDoubleClick : any ;

}

interface ListingProcessingManager {
    initialize : boolean
}

export const handleChangeObjectState = (setState:any, name:any, val:any) => setState((state:any) => {return{...state, [name] : val}})



export default function ListingProcessing(props:ListingProcessingProps) {
    const [manager, setManager] = useState<ListingProcessingManager>({
        initialize : false
    })

    useEffect(() => {
        if(!manager.initialize) {
            if(props.data_object.to_revise.length > 0) {
                props.setEvents((state:any) => {return{...state, active : "TO-REVISE"}})
            } else {
                props.setEvents((state:any) => {return{...state, active : "READY"}})
            }
            setManager((state:any) => {return{...state, initialize : true}})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manager.initialize])


    return (
        <React.Fragment>
            {
                props.data_object.to_revise.length > 0 ? 
                    <ManagerCat 
                        nameClass = "to-revise"
                        category = {`À réviser (${props.data_object.to_revise.length}/${props.data.length})`}
                        identification = "TO-REVISE"
                        active = {props.events.active}
                        setActive = {() => props.events.active === "TO-REVISE" ? handleChangeObjectState(props.setEvents, 'active', 'READY') : handleChangeObjectState(props.setEvents, 'active', 'TO-REVISE')}
                        data = {props.data_object.to_revise}
                        selected_data = {props.manager.selected_data}
                        handleSelect = {(e:any) => props.handleSelect(e)}
                        handleDoubleClick = {props.handleDoubleClick}
                    />
                : 
                    null
            }
            
            {
                props.data_object.ready.length > 0 ? 
                    <ManagerCat 
                        nameClass = "ready"
                        category = {`Prêt (${props.data_object.ready.length}/${props.data.length})`}
                        identification = "READY"
                        active = {props.events.active}
                        setActive = {() => props.events.active === "TO-REVISE" ? handleChangeObjectState(props.setEvents, 'active', 'READY') : handleChangeObjectState(props.setEvents, 'active', 'TO-REVISE')}
                        data = {props.data_object.ready}
                        selected_data = {props.manager.selected_data}
                        handleSelect = {(e:any) => props.handleSelect(e)}
                        handleDoubleClick = {props.handleDoubleClick}
                    />
                : 
                    null
            }
        </React.Fragment>
    )
}

function ManagerCat(props:any) {
    return (
        <div 
            style = {props.active === props.identification ? {height : "calc(100% - 30px)", flex : "1 1"} : {height : "30px", flex : "inherit"}}
            className={`manager-cat ${props.nameClass ? props.nameClass : ""}`}
        >
            <h1 onClick = {props.setActive}>
                <span>{props.category}</span>
                <div><Icons name = {props.active === props.identification ? 'caret-down' : "caret-up"} mode = "duotone" color = "white" /></div>
            </h1>

            {
                props.active === props.identification ? 
                    <motion.div
                        exit = {{height : 0, opacity : 0}}
                        animate = {{height : 'auto', opacity : 1, transition : {duration : 0.1}}}
                        initial = {{height : 0, opacity : 0}}
                    >
                        <div className='thead'>
                            <div>Date</div>
                            <div>Fournisseur</div>
                            <div>Total TTC</div>
                        </div>
                        <div className='tbody'>
                            <div>
                            {
                                props.data.map((dt:any) => (
                                    <div 
                                        className = {`tbody--element ${props.selected_data === dt.url ? "active" : ""}`}
                                        key = {JSON.stringify(dt.url)} 
                                        onClick = {() => props.handleSelect(dt.url)}
                                        onDoubleClick={() => props.handleDoubleClick(dt)}
                                    >
                                        <div>{StringUtils.isUndefinedValue(dt.dates.due_date) ? "À définir" : `${moment(dt.dates.due_date).format("DD/MM/YYYY")}`}</div>
                                        <div>{dt.id_supplier._id.length === 0 ? "À définir" : dt.id_supplier.name}</div>
                                        <div>{StringUtils.isUndefinedValue(dt.total_ttc) ? "À définir" : `${dt.total_ttc} €`}</div>
                                    </div>
            
                                ))
                            }
                            </div>
                        </div>
                    </motion.div>

                : 
                    null
            }
        </div>
    )
}