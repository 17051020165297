import { TabList } from "@mui/lab";
import { fontWeight, height, styled, width } from "@mui/system";
import { color } from "framer-motion";
import { cursorTo } from "readline";
import { text } from "stream/consumers";

export const color_1 = '#116633';
export const color_2 = '#1f854d';
export const color_3 = '#35a464';
export const color_4 = '#4bba7a';

export const textField_sx = { 
    '.MuiInputBase-root' : {

        height: 40, 
    }, 
    '.MuiInputBase-input': { 
        height: 50, 
        padding: '5px 14px', 
        fontSize: '0.875rem',  // Taille de la police ajustée
        color: '#116633', // Couleur du label en focus
        fontWeight : 300, 
    }, 
    '.MuiInputLabel-root': { 
        lineHeight: '1rem', // Ajustez selon vos besoins
        top: '-5px', // Ajustez la position de l'étiquette si nécessaire
        color: '#116633', // Couleur du label en focus
        display : "flex"
  
    }, 
    '.MuiInputLabel-shrink': { 
        color: '#116633', // Couleur du label en focus
        top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
    },
  
    '.MuiInputLabel-root.Mui-focused': { 
        color: '#1f854d', // Couleur du label en focus
        top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
    },
    '& .MuiOutlinedInput-root': {
        // Bordure en non focus
        '&:hover fieldset': {
            borderColor: '#116633',
            opacity: 0.8, 
            borderRadius: 10, // Ajustez selon vos besoins
        },
        '& fieldset': {
            borderColor: '#116633',
            borderRadius: 10, // Ajustez selon vos besoins
        },
        '& input:-webkit-autofill, & input::-webkit-input-placeholder': {
            height : 30,
            backgroundColor: 'transparent!important',
            borderRadius: 10, // Ajustez selon vos besoins
        },
    },
  
}

export const select_sx = {
    
};
  
  

export const button_sx = {
    //color : color_2, 
    height : "30px", 
    //backgroundColor : color_1,
    textTransform : "none",
    borderRadius : "30px",
    fontSize : "0.775rem",
    /*"&:hover" : {
        backgroundColor : color_3
    }*/

}

export const table_header_sx = {
    color: '#89fcac', // Couleur de l'écriture en blanc
    backgroundColor: '#060606',
    cursor: 'pointer',
}

export const table_tbody_sx = {
    color: 'white', // Couleur de l'écriture en blanc
    backgroundColor: '#060606',
}

export const checkbox_sx = {
    color : `${color_1}!important`, 

}

export const tabs_sx = {
    tab_list : {
        '.Mui-selected' : {
            color : `${color_1}!important`, 
            borderColor : color_1
        }, 
        "& .MuiTabs-indicator": {
            color: `${color_1}!important`, // Changez ici pour la couleur que vous souhaitez
            backgroundColor: color_1, // Changez ici pour la couleur que vous souhaitez
        },
    }, 
    tab : {
        color : "white", 
        borderRadius : "10px",
    }, 

}

export const data_grid_x_sx = {
    "& .MuiDataGrid-virtualScrollerContent *" : {
        color : "#163", 
        cursor : "pointer", 
        fontWeight : "300", 
        "& .MuiDataGrid-row" : {
            cursor:"pointer"
        }
    }, 
    ".MuiDataGrid-columnHeaders *" : {
        color : "white", 
    }, 
    "& .MuiDataGrid-row" : {
        "& > div" : {
            backgroundColor : "#fefefe!important",
        }, 
        "&:hover > div" : {
            backgroundColor : "#d2ebdc!important"
        }, 
    }, 
    " & .MuiDataGrid-row.Mui-selected" : {
        "& > div" :  {
            backgroundColor : "#d2ebdc!important", 
            fontWeight : "400!important", 
        }


    }, 
    ".MuiDataGrid-columnHeaders > div" : {
        background : "#35a464!important"
    }, 
    "& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader" : {
        height : "40px!important"
    }, 
    "& .MuiDataGrid-virtualScrollerRenderZone" : {
        "& .MuiDataGrid-row" : {
            backgroundColor : "#fefefe!important", 

            "& .MuiDataGrid-cell" : {
                border: "0.5px solid #80808024!important", 

            }, 

            
        }
    }, 
    "& .MuiDataGrid-columnHeaders" : {
        "& .MuiDataGrid-columnHeaderTitleContainer" : {
            display: "flex",
            justifyContent: "center",
        }
    }, 
    "& .MuiDataGrid-cell--editing" : {
        backgroundColor : "#060606!important"
    }, 
    "& .MuiDataGrid-overlayWrapper" : {
        width :"100%", 
        height : "100%", 
        backgroundColor : "#fefefe94!important",
        color : "white", 
        position: "absolute", 
        top: "-0px", 
        backdropFilter: "blur(3px)",

        "& svg" : {
            color : "#35a464"
        }
    },
    "& .MuiDataGrid-columnHeader--pinnedLeft, .MuiDataGrid-columnHeader--pinnedRight" : {
        backgroundColor : color_2
    }
    
}

export const dropdown_sx = { 
    '.MuiInputBase-root' : {
        height: 45, 
        display : "flex", 
        alignItems : "flex-end"
    }, 
    '.MuiInputBase-input': { 
        height: 50, 
        padding: '5px 14px', 
        fontSize: '0.875rem',  // Taille de la police ajustée
        color: '#116633', // Couleur du label en focus
        fontWeight : 300, 
    }, 
    "& .MuiSelect-select" : {
        marginBottom : "2px"
    }, 
    '.MuiInputLabel-root': { 
        lineHeight: '1rem', // Ajustez selon vos besoins
        top: '-5px', // Ajustez la position de l'étiquette si nécessaire
        color: '#116633', // Couleur du label en focus

  
    }, 
    '.MuiInputLabel-shrink': { 
        color: '#116633', // Couleur du label en focus
        top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
    },
  
    '.MuiInputLabel-root.Mui-focused': { 
        color: '#1f854d', // Couleur du label en focus
        top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
    },
    '& .MuiOutlinedInput-root': {
        // Bordure en non focus
        '&:hover fieldset': {
            borderColor: '#116633',
            opacity: 0.8, 
            borderRadius: 10, // Ajustez selon vos besoins
        },
        '& fieldset': {
            borderColor: '#116633',
            borderRadius: 10, // Ajustez selon vos besoins
        },
        "& .MuiOutlinedInput-notchedOutline legend" : {
            backgroundColor : "transparent", 
            visibility : "visible", 
            height : "20px", 

            "& span" : {
                opacity : 1, 
                marginBottom : "10px", 
                color: '#116633'
            }
        }, 
        '& input:-webkit-autofill, & input::-webkit-input-placeholder': {
            height : 30,
            backgroundColor: 'transparent!important',
            borderRadius: 10, // Ajustez selon vos besoins
        },
    },
  
}