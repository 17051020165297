import React, {useState} from 'react' ; 
import {motion} from 'framer-motion'
import { TableUtils } from 'tc-minibox';


export interface TotalsProps {
    total_ttc : number, 
    total_ht : number, 
    total_tva : number, 
}

export default function Totals(props:TotalsProps) {
    const [hover, setHover] = useState(false)

    return (
        <motion.div 
            exit = {{opacity : 0}}
            animate = {{opacity : 1, transition : { delay : 0.2, type : "linear"}}}
            initial = {{opacity : 0}}
            className='totals-view'
        >
            <p onMouseEnter = {() => setHover(true)} onMouseLeave = {() => setHover(false)}>
                <span>Total TTC :</span><span>{` ${TableUtils.formatNumberWithSpaces(props.total_ttc) } €`}</span>
            </p>

            {
                hover ? 
                    <motion.div 
                        exit = {{ height : 0, opacity : 0, y : -33}}
                        animate = {{ height : 'auto', opacity : 1, y : -57}}
                        initial = {{ height : 0, opacity : 0, y : -53}}
                        className = "totals-view--detail" 
                        onMouseEnter = {() => setHover(true)} 
                        onMouseLeave = {() => setHover(false)}
                    >
                        <p>
                            <span>Total HT :</span><span>{` ${TableUtils.formatNumberWithSpaces(props.total_ht)} €`}</span>
                        </p>
                        <p>
                            <span>Total TVA :</span><span>{` ${TableUtils.formatNumberWithSpaces(props.total_tva)} €`}</span>
                        </p>
                    </motion.div>
                : 
                    null
            }

        </motion.div>
    )
}