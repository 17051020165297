import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { checkInvStatus, filter_dext } from "./utils.deposit"
import { addRequestStatus, setLoaderStatus } from "../../../../../store/global/actions.global"
import { ServerProps} from "../../../../../config/server.config"
import { SupplierDepositManager } from "../Deposit"
import {ReactComponent as SelectIllustration} from '../../../../../assets/svg/illustrations/select__illustration.svg' ;
import {ReactComponent as ArrowLeft} from '../../../../../assets/svg/icons/arrow_left.svg' ; 
import {ReactComponent as ArrowRight} from '../../../../../assets/svg/icons/arrow_right.svg' ;  
import { Icons, StringUtils } from "tc-minibox" ; 
import UpdatingInvoice from "../update/UpdateInv"
import ListingProcessing from "./grids/ListingInvDeposit"
import { account_user } from "../../../../../store/account/selector.account"


export interface InvoiceProcessingProps extends ServerProps {
    rerender_affairs : () => void, 
    id_affair : string | number | null,
    data : any[], 
    setData : React.Dispatch<React.SetStateAction<any[]>>,
    manager : SupplierDepositManager, 
    setManager : React.Dispatch<React.SetStateAction<SupplierDepositManager>>,
    handleClose : (status:boolean) => void,
}


export const handleChangeObjectState = (setState:any, name:any, val:any) => setState((state:any) => {return{...state, [name] : val}})

export interface InvoiceProcessingManager {
    mode : "listing" | "update" | "load",
    selected_data : string | null | number, 
}

export interface InvoiceProcessingEvents {
    active : "TO-REVISE" | "READY", 
}

export default function InvoiceProcessing(props:InvoiceProcessingProps) {
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)
    //const filtered_data = filter_dext(props.data)

    const [manager, setManager]= useState<InvoiceProcessingManager>({
        selected_data : null, 
        mode : "listing"
    })
    const [events, setEvents] = useState<InvoiceProcessingEvents>({
        active : "TO-REVISE"
    })

    const data_object = filter_dext(props.data.map((rs => {return{...rs}})))

    const handleSelect = (val:any) => {
        setManager(state => {
            return {
                ...state, 
                status : "pending", 
                selected_data : val
            }
        })
    }

    const handleDoubleClick = () => {
        setManager(state => {
            return {
                ...state, 
                status : "resolve",
                mode : "update"
            }
        })
    }
    const handleSubmit = () => {
        
        const data_transformed = data_object.ready.map(dt => {
            return {
                ...dt, 
                id_affair : props.id_affair, 
                id_user : user_account.infos._id, 
                status_log : [{
                    status: "pending",
                    user: user_account.infos._id,
                    date: Date.now(),
                    comment: ""
                }], 
                
            }
        })


        dispatch(setLoaderStatus({status : true}))

        props.server.post('/invoicing/deposit', data_transformed) 
        .then(res => {

            dispatch(addRequestStatus(res.data))
            props.handleClose(false)
            props.rerender_affairs()
            dispatch(setLoaderStatus({status : false}))

        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }
    

    return (
        //<iframe src={`${data.to_revise[0].url}`} title="Web Page Viewer" className="iframe-container" />

        <div  className='invoice-processing'>
                <div className='preview'>
                    {
                        props.data.find(dt => dt.url === manager.selected_data) ? 
                            <iframe src={`${manager.selected_data}`} title="Web Page Viewer" className="iframe-container"/>
                        : 
                            <div className='no-data'>
                                <SelectIllustration />
                                <p>Sélectionnez une donnée pour en prévisualiser le contenu.</p>
                            </div>
                    }
                </div>

                <div className='manager' >
                    <div className='manager--header'>
                        <div className='return' onClick = {() => manager.mode === "update" ? handleChangeObjectState(setManager, "mode", "listing") : handleChangeObjectState(props.setManager, "mode", "import")}>
                            <Icons name = "arrow-turn" mode = "duotone" color = "white" />
                        </div>

                        {
                            !StringUtils.isUndefinedValue(manager.selected_data) ?
                                <div 
                                    className='delete' 
                                    onClick = {() => {
                                        
                                        props.setData(state => state.filter(dt => dt.url !== manager.selected_data))
                                        setManager(state => {return {...state, mode : "listing", selected_data : null}})
                                    }}
                                >
                                    <Icons name = "trash" mode = "duotone" color = "white"/>
                                </div>
                            : 
                             null
                        }


                        <div className='buttons'>
                            {
                                manager.mode === "listing" ? 
                                filter_dext(props.data).ready.length > 0 ? 
                                        <div className='buttons--listing'>
                                            <div className='save' onClick = {handleSubmit}>
                                                <Icons name = "floppy-disk" mode = "duotone" color = "white"/>
                                            </div>
                                        </div>
                                    : 
                                        null
                                : 
                                    <div className='buttons--update'>
                                        <div
                                            style = {{
                                                opacity : props.data.findIndex(dt => dt.url === manager.selected_data) === 0 ? 0.5 : 1, 
                                                cursor : props.data.findIndex(dt => dt.url === manager.selected_data) === 0 ? "not-allowed" : "pointer"
                                            }}
                                            onClick = {() => {
                                                const index = props.data.findIndex(dt => dt.url === manager.selected_data)
                                                if(index > 0) {
                                                    setManager(state => {
                                                        return {
                                                            ...state, 
                                                            status : "pending", 
                                                            selected_data : props.data[index - 1].url
                                                        }
                                                    })
                                                }
                                            }}
                                        >
                                            <ArrowLeft />
                                        </div>

                                        <p>{`${props.data.findIndex(dt => dt.url === manager.selected_data) + 1}/${props.data.length}`}</p>

                                        <div
                                            style = {{
                                                opacity : props.data.findIndex(dt => dt.url === manager.selected_data) === props.data.length - 1 ? 0.5 : 1,
                                                cursor : props.data.findIndex(dt => dt.url === manager.selected_data) === props.data.length - 1 ? "not-allowed" : "pointer"
                                            }}
                                            onClick={() => {
                                                const index = props.data.findIndex(dt => dt.url === manager.selected_data)
                                                if(index < props.data.length - 1) {
                                                    setManager(state => {
                                                        return {
                                                            ...state, 
                                                            status : "pending", 
                                                            selected_data : props.data[index + 1].url
                                                        }
                                                    })
                                                }
                                            }}
                                        >
                                            <ArrowRight />
                                        </div>
                                        
                                    </div>
                            }
                        </div>

                    </div>
                    <div className='manager--container'>
                        {
                            manager.mode === "update" ? 
                                <UpdatingInvoice
                                    key_id = {manager.selected_data}
                                    server = {props.server}
                                    selected_data = {manager.selected_data}
                                    status_data = {checkInvStatus(props.data.find(dt => dt.url === manager.selected_data))}
                                    status_config = {[
                                        {
                                            id : "STATUS-CONFIG-00", 
                                            bool : true, 
                                            name : "ready", 
                                            text : "Prêt"
                                        }, 
                                        {
                                            id : "STATUS-CONFIG-01", 
                                            bool : false, 
                                            name : "to_revise", 
                                            text : "À réviser"
                                        }, 
                                    ]}
                                    data = {props.data}
                                    setData = {props.setData}
                                />
                            : 
                                <ListingProcessing
                                    data_object = {data_object}
                                    data = {props.data}
                                    events = {events}
                                    setEvents = {setEvents}
                                    handleSelect = {handleSelect}
                                    handleDoubleClick = {handleDoubleClick}
                                    manager = {manager}
                                />

                        }

                    </div>
                </div>
        </div>

    )
}