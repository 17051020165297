import { useEffect, useRef, useState } from "react";
import { ServerProps } from "../../../../config/server.config";
import BasePopup from "../../../../tools/components/general/BasePopup";
import DropDown from "../../../../tools/components/general/DropDown";
import { paymentStatuses } from "../../payment.utils";
import MainButton from "../../../../tools/components/general/MainButton";
import { v4 } from "uuid";
import { account_user } from "../../../../store/account/selector.account";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Icons } from "tc-minibox";
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global";
import {ReactComponent as SelectSVG} from "../../../../../assets/svg/illustrations/select__illustration.svg"
import { get } from "http";


export interface ChangeStatusPopupProps extends ServerProps {
    handleClose : () => void, 
    rerender : () => void,
    selected_data : string[], 
    data : any, 
    setData : React.SetStateAction<any>
}

type EventsProps = {
    hover : null | string
}

type NewStatus = {
    status : string,
    comment : string, 
    full_pay : boolean, 
    pay_date : Date
}

export default function ChangeStatusPopup(props:ChangeStatusPopupProps) {
    const user_account = useSelector(account_user)
    const dispatch = useDispatch()
    const get_info_selected_data = props.data.filter((dt:any) => props.selected_data.includes(dt._id))

    const [form, setForm] = useState<NewStatus>({
        status : "paid-awaiting-reconciliation",
        comment : "", 
        full_pay : false, 
        pay_date : new Date(props.data.find((dt:any) => dt._id === props.selected_data[0]).dates.due_date)
    })
    const [events, setEvents] = useState<EventsProps>({
        hover : null
    })

    const handleSubmit = () => {
        //CHANGER LE STATUS
        const newCommentLog = {
            id : v4(), 
            name : form.status, 
            content : form.comment,
            date : new Date(), 
            user : user_account.infos._id,
        }

        const getInvs = props.data.filter((dt:any) => props.selected_data.includes(dt._id))
        const new_payments = getInvs.filter((inv:any) => {
            const totalPaidAmount = inv.payments_log.reduce((total:number, payment:any) => total + parseFloat(payment.amount), 0);
            const restToPay = (parseFloat(inv.total_ttc) - totalPaidAmount).toFixed(2);

            return parseFloat(restToPay) !== 0
        }).map((inv:any) => {
            const totalPaidAmount = inv.payments_log.reduce((total:number, payment:any) => total + parseFloat(payment.amount), 0);
            const restToPay = (parseFloat(inv.total_ttc) - totalPaidAmount).toFixed(2);
            return {
                _id : inv._id, 
                content : {
                    id : v4(), 
                    date : form.pay_date, 
                    amount : parseFloat(restToPay), 
                    user : user_account.infos._id
                }
            }
        })
        dispatch(setLoaderStatus({status : true}))

        props.server.post('/payments/bulk-update-log', {
            selected_data : props.selected_data, 
            new_comment : newCommentLog,
            full_pay : form.full_pay,
            new_payments : new_payments,
        })

        .then((res )=> {
            props.handleClose()
            props.setData((state:any) => {
                return state.map((dt:any) => {
                    if(props.selected_data.includes(dt._id)) {
                        const getInv = res.data.data.find((inv:any) => inv._id === dt._id)

                        return {
                            ...dt, 
                            payments_log : getInv.payments_log, 
                            comments_log : getInv.comments_log
                        }
                    }
                    return dt
                })
            })
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus({
                status : res.data.status,
                message : res.data.message
            }))
        })
        .catch(err => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(err.response.data))
        })

        /*
.map((dt:any) => {
            const totalPaidAmount = paymentsLog.reduce((total, payment) => total + parseFloat(payment.amount), 0);
            const restToPay = (parseFloat(props.data.total_ttc) - totalPaidAmount).toFixed(2);
            return {
                _id : v4(), 
                content : {
                    id : v4(), 
                    date : form.pay_date, 

                }
            }
        })
        */
        //SI IL FAUT SOLDER LA FACTURE
    }

    // * USE EFFECTS
    useEffect(() => {
        const handleKeyPress = (e:any) => {
            if(e.key === "Enter") {
                handleSubmit()
            }
        }
        document.addEventListener('keydown', handleKeyPress)
        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BasePopup
            nameClass="change-status-popup"
            handleClose = {props.handleClose}
        >
            <div id = "form">
                <div id="new-status">
                    <label htmlFor="status">Définissez un nouveau statut</label>
                    <DropDown
                        reference = {"CHANGE-STATUS-EL"}
                        hover = {events.hover}
                        setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                        value = {form.status}
                        setValue = {(e) => {
                            setForm(state => {return{...state, status : e}})}}
                        list = {paymentStatuses.map((el:any) => {
                            return {
                                id : el.id as string,
                                name : el.name as string,
                                text : el.title as string
                            }
                        })}
                    />
                </div>

                <div id ="comment">
                    <label htmlFor="comment">Commentaire</label>
                    <textarea
                        value={form.comment}
                        onChange={(e) => setForm((state:any) => {return{...state, comment : e.target.value}})}
                        placeholder="Tapez ici...."
                    >

                    </textarea>
                </div>

                {
                    ["paid-awaiting-reconciliation", "reconciled-invoice"].includes(form.status) ?
                        <div id = "full-pay">
                            <label htmlFor="full-pay">Souhaitez vous solder la facture ?</label>
                            <div>
                                <div
                                    id = {form.full_pay ? "full-pay__active--yes" : ""}
                                    onClick = {() => setForm(state => {return{...state, full_pay : true}})}
                                >
                                    Oui
                                </div>
                                <div
                                    id = {form.full_pay ? "" : "full-pay__active--no"}
                                    onClick = {() => setForm(state => {return{...state, full_pay : false}})}
                                >
                                    Non
                                </div>
                            </div>
                        </div>
                    : 
                    null
                }

                {
                    form.full_pay ? 
                        <div id="pay_date">
                            <label htmlFor="pay_date">Date de paiement</label>
                            <DatePicker
                                selected={form.pay_date} 
                                dateFormat="dd/MM/yyyy"
                                onChange = {(e:any) => setForm(state => {return{...state, pay_date : e}})}
                                locale="fr"   
                            />
                        </div>
                    :
                    null
                
                }

                <MainButton
                    handleClick = {() => {
                        handleSubmit()
                    }}
                >
                    Valider
                </MainButton>
            </div>
            {
                <div className="preview">
                <h1>Factures sélectionnées</h1>
                <div className="preview--container">
                    <div>
                    {
                        get_info_selected_data.map((dt:any, index:number) => {
                            return (
                                <div key = {`GET_INF_SELECTED-DATA-${index}`}>
                                    <p id = "inv_number">{dt.inv_number} - {dt.id_supplier.name}</p>
                                    <p id = "doc_number">{dt.doc_number}</p>
                                    <p id = "amount">{dt.total_ttc}€</p>
                                </div>
                            )
                        })
                    }
                    </div>

                </div>
            </div>
                
            }


        
        </BasePopup>
    )
}